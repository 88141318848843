.d8-c-lucky-modal {
  // background-color: var(--color-bg);
  .lucky-button {
    border: 1px solid var(--color-contrast-low);
    &.active {
      // background-color: var(--color-primary-300);
      border: 1px solid var(--color-primary);
      box-shadow: none;
      h3 {
        color: var(--color-primary);
      }
      svg {
        path {
            stroke: var(--color-primary);
        }
      }
      .d8-week-row-skew-wrapper{
        opacity: 1;
      }
    }
    &:hover {
      border: 1px solid var(--color-primary);
      .d8-u-hover-shadow-md {
        box-shadow: none;
      }

      h3 {
        color: var(--color-primary);
      }
      svg {
        path {
            stroke: var(--color-primary);
        }
      }
    }
  }
  .d8-week-row-skew-wrapper{
    opacity: 0;
    transform-origin: bottom left;
      -ms-transform: skew(-40deg, 0deg);
      -webkit-transform: skew(-40deg, 0deg);
      transform: skew(-40deg, 0deg);
      height: 100%;
      width: 60%;
      max-width: 300px;
      top: 0;
      right: 0;
      position: absolute;
      overflow: hidden;

      .d8-week-row-img {
        height: 100%;
        width: 100%;
        right: 0px;
        top: 0px;
        transform-origin: bottom left;
        -ms-transform: skew(40deg, 0deg);
        -webkit-transform: skew(40deg, 0deg);
        transform: skew(40deg, 0deg);
        object-fit: cover;
      }
  }
  .step-destinations {
    .d8-c-card {
      border: 3px solid transparent;
      box-shadow: none;
      &.active {
        border: 3px solid var(--color-primary);
        // box-shadow: var(--shadow-sm);
      }

    }
  }
}