.sea-myth {
    @import url("https://use.typekit.net/yrl0krq.css");
    background-color: #EEEDE7;
    color: #1E314F;

    h1, h2, h3, h4, h5 {
        font-family: "modesto-condensed", serif;
        font-weight: 700;
        font-style: normal;
        color: #1E314F;
    }

    .sea-myth-header {
        min-height: auto;
    }

    .paper-tear {
        transform: translateY(-50%);
        z-index:1;
        svg {
            width: 100%;
            min-width: 1200px;
        }

        @media (min-width: 48em) {
            transform: translateY(-53%);

            svg {
                min-width: 160vw;
            }
        }
    }

    .sea-myth-intro {
        background-image: url("https://assets.theyachtweek.com/wp-content/uploads/2023/01/map1.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .repeat-map {
        background-image: url("https://assets.theyachtweek.com/wp-content/uploads/2023/01/map1.png");
        background-size: 100% auto;
        background-repeat: repeat-y;
    }

    .d8-c-carousel {
        .custom-arrows {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            padding: 0;
            top: 90%;

            &.next {
                right: 45%;
            }

            &.prev {
                left: 45%;
            }

            @media (min-width: 48em) {
                top: 50%;

                &.next {
                    right: 24px;
                }

                &.prev {
                    left: 24px;
                }
            }

            svg {
                g {
                    path {
                        fill: transparent;
                    }
                }
            }

            &.prev {
                background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/arrowleft.png');
            }

            &.next {
                background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/arrowright.png');  
            }
        }
    }

    .sea-myth-slider-item {
        width: 100vw;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        color: #FFE000;

        .event-svg {
            max-height: 150px;
            max-width: 80%;

            @media (min-width: 48em) {
                max-height: 300px;
            }
        }

        .sea-myth-background {
            background-color: #231F20;
            opacity: .54;
            z-index:0;
        }


        .bordered-content {
            max-width:100%;

            @media (min-width: 48em){
                max-width: 90%;
                margin-left: auto;
                margin-right:auto;
            }
        }

        &.moby {
            background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/seamythslide1.png');
        }
        &.fortress {
            background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/seamythslide2.png');
        }
        &.race {
            background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/seamythslide3.png');
        }
        &.eye {
            background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/seamythslide4.jpg');
        }
    }

    .sea-myth-adventure {
        svg {
            max-width: 70%;
        }
    }

    .sea-myth-destinations {
        .d8-c-card .card--image {
            &::before {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, .3);
                content: " ";
                z-index:1;
            }

            .image--destination__bottom {
                top: 0;
                position: absolute;
                height: 100%;
                width: 100%;
                padding: 0;
                display: flex;
                justify-content: center; 
                align-items: center;

                background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/locationborder.png');
                background-size: 95% 96%;
                background-repeat: no-repeat;
                background-position: center;

                .text-component {
                    h3, p {
                        text-align: center;
                        color: #FFE000;
                    }

                    h3 {
                        @extend .text-xxl;

                        span {
                            font-size: 18px !important;
                        }
                    }

                    p {
                        width: 100%;
                        border-right: 0;
                        @extend .text-sm;

                        span {
                            display: none;
                        }
                    }

                    @media (min-width: 48em) {
                        .btn {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .squid-footer {
        width: 100%;
        margin-bottom: -5px;
        position: relative;
        overflow:hidden;

        .footer-shape {
            max-width:unset;
            width:160vw;
            margin-left: -28vw;

            @media (min-width: 48em) {
                display: block;
	            max-width: 100%;
                width:100%;
	            height: auto;
                margin-left:0;
            }
        }
    }

    .sea-myth-footer {
        background-color: #1E314F;
    }
    
    .d8-c-hero {
        height: 100%;
    }

    @media (min-width: 48em) {
        .hero-content {
            width: 40%;
            .text-component {
                & > div {
                    flex-wrap: wrap;
                    justify-content: center;

                    & > div {
                        max-width: 40%;
                        flex-basis: auto;
                    }

                    & > div:last-child {
                        max-width: 55%;
                    }
                }
            }
        }
    }

}