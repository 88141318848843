// !!!!!! ADEED TEMPORARY TO MAKE TYW STYLES WORK
@import "../node_modules/day8-react/build/styles/tyw/variables.scss";
@import "../node_modules/day8-react/build/styles/styles.scss";
@import "./styles_keep_here/bits.scss";
@import "./styles_keep_here/bbyl.scss";
@import "./styles_keep_here/custom-dropdown.scss";

// NEW COMPONENTS
@import "../node_modules/day8-react/build/styles/design-system/styles.scss";

@import "./styles_keep_here/yacht-vs-cabin.scss";
@import './styles_keep_here/ultra.scss';
@import './styles_keep_here/destination-haywire.scss';
@import './styles_keep_here/destination-vibras.scss';
@import './styles_keep_here/destination-peche.scss';
@import './styles_keep_here/tahiti.scss';
@import './styles_keep_here/hideout.scss';
@import './styles_keep_here/cirquedusail.scss';
@import './styles_keep_here/bvi.scss'; //here
@import './styles_keep_here/home.scss';
@import './styles_keep_here/dark-mode.scss';
@import './styles_keep_here/how-it-works.scss';
@import './styles_keep_here/destination-guide.scss';
@import './styles_keep_here/music.scss';
@import './styles_keep_here/christmas.scss';
@import './styles_keep_here/netweek.scss';
@import './styles_keep_here/sign-up-2022.scss';
@import './styles_keep_here/sign-up-2023.scss';
@import './styles_keep_here/gday-sailors.scss';
@import './styles_keep_here/welcome-landing-page.scss';
@import './styles_keep_here/destination-yachtnik.scss';
@import './styles_keep_here/signature-events.scss';
@import './styles_keep_here/hotcreations.scss';
@import './styles_keep_here/drumcode.scss';
@import './styles_keep_here/wanderland.scss';
@import './styles_keep_here/turkey.scss';
@import './styles_keep_here/destination-hip-hop.scss';
@import './styles_keep_here/landing-2023.scss';
@import './styles_keep_here/applebum.scss';
@import './styles_keep_here/sea-myth.scss';
@import './styles_keep_here/bo-2024.scss';
@import './styles_keep_here/partners.scss';
@import './styles_keep_here/bbyl-2023.scss';
@import './styles_keep_here/tyw-why-page.scss';
@import './styles_keep_here/destination-greece-bootcamp.scss';
@import './styles_keep_here/cabins.scss';
@import './styles_keep_here/destination-sardinia-40.scss';
@import './styles_keep_here/summer-of-love.scss';
@import './styles_keep_here/ywcollective.scss';
@import './styles_keep_here/lead-booker.scss';
@import './styles_keep_here/lucky-modal.scss';

// Codyhouse custom style
@import "./custom-style/typography";

/* Slick reset */
.slick-prev, .slick-next {
    &::before {
        display: none;
    }
}
@import "../node_modules/day8-ui/lib/index.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import "./themes/theme-default.css";
  @import "./themes/theme-ultra.css";
  @import "./themes/theme-cash-money.css";
}

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
   /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
  }
}

.dui-blog-item-wrapper {
    h1, h2, h3, h4, p {
      margin-top: 1rem;
    //   margin-bottom: var(--space-md);
    }
    h1, h2 {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-top: 2rem
    }

    h3, h4 {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    .d8-blog-content img {
      margin-top: 1rem;
    }
    .d8-blog-content .wp-caption{
      margin-top: 1rem;;
      margin-bottom: 1rem;;
      max-width: 100%;
      img{
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    iframe {
        width: 100%;
        height: 100%;
    }
    figure {
      margin-top: 1rem;
      margin-bottom: 1rem;

        .wp-block-embed__wrapper {
            aspect-ratio: 16 / 9;
            width: 100%;
            object-fit: cover;
        }
      &.alignright {
        text-align: right;
      }

      &.aligncenter {
        text-align: center;
      }

      figcaption {
        margin-top: 1rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      img {
        margin: 0px;
      }
    }

    em {
      font-style: italic;
    }
    ul {
      list-style-type: disc;
      margin-left: 1rem;
      padding: 1rem;
    }
  }
