.gday-sailors {
  --color-bg: #fff;
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 100%;
  --color-primary: #8995F0;

  h1 {
    font-family: playfair-display,serif;
    font-weight: 400;
    font-style: normal;
    color: #000;
    display: inline-block;
    background-color: lightblue;
    border: 1px solid #000;
    padding: 0px 10px;
    position: relative;
    height: 60px;
    box-sizing: border-box;
    &:after {
      content: "";
      width: calc(100% + 2px);
      height: 20px;
      border: 1px solid #000;
      transform: skewX(-45deg);
      position: absolute;
      left: -11.5px;
      display: block;
      bottom: -21px;
      background-color: #6da1b2;
      box-sizing: border-box;
      border-top: none;
      border-left: none;
    }
    &:before {
      content: "";
      width: 20px;
      height: 60px;
      border: 1px solid #000;
      transform: skewY(-45deg);
      position: absolute;
      left: -21px;
      display: block;
      top: 9px;
      background-color: #6da1b2;
      box-sizing: border-box;
      border-right: none;
    }

    b {
      font-weight: 600;
    }
  }

  h2 {
    font-family: slabo-13px, serif;
    font-style: normal;
    font-weight: 400;
    color: #4C608C;
    text-align: center;
  }

  h3 {
    color: #EB636F;
  }
  .d8-c-hero {
    height: 40vh;
    min-height: 500px;
  }
  .d8-u-super-dark-blue-bg {
    background-color: #8995F0;
  }
  // svg
  .tyw-logo-svg {
    width: 180px;
    height: 180px;
  }
  .hero_half_circle-svg {
    bottom: -1px;
  }
  .wiggle-svg {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .half_circle-svg {
    top: 0;
    right: 0;
  }
  .tabs_decoration_1-svg {
    top: 0;
    left: 0;
  }
  .tabs_wiggle-svg {
    top: 80%;
    right: 0;
  }
  .tabs_decoration_2-svg {
    top: 0;
    right: 0;
  }
  .tabs_circle-svg {
    top: -40%;
    left: 0;
  }
  .flex-highlight-grid-wrapper {
    .d8-c-card-slider {
      .slider--row {
        color: #4C608C;

        .card-slider-controls {
          background: transparent;
        }
      }
    }
  }
  .signup-footer-wrapper {
    background-image: url(https://assets.theyachtweek.com/wp-content/uploads/2022/03/footer-4.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: var(--space-xxxl);
  }

  .signUp-button-container {
    width: 100%;
    height: 60px;
    max-width: 300px;
    display: inline-block;
    position: relative;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid #000;
      top: 10px;
      left: 10px;
      display: inline-block;
      position: absolute;
    }

    .signUp-button {
      width: 100%;
      height: 100%;
      border-radius: 0;
      background-color: #4b608c;
      border: none;
      line-height: 150%;
      position: relative;
    }
  }

  // new svg
  .wonna_dance {
    margin: auto;
    display: none;

    @include breakpoint(md) {
      display: block;
      bottom: -48px;
      right: 10px;
      height: 100px;
      width: 200px;
    }
    @include breakpoint(lg) {
      bottom: -110px;
      right: 30px;
      height: 150px;
      width: auto;
    }
    @include breakpoint(xl) {
      right: 10%;
      height: auto;
    }
  }

  .euro_trip_wrapper {
    padding-bottom: 50px;
    @include breakpoint(xs) {
      padding-bottom: 0;
    }

    .euro_trip {
      z-index: 2;
      left: -122px;
      height: 200px;
      top: -72px;

      @include breakpoint(xs) {
        left: -127px;
        height: 250px;
        top: -130px;
      }
      @include breakpoint(sm) {
        left: -90px;
        top: -180px;
        height: 300px;
      }
      @include breakpoint(md) {
        left: 0;
        top: -300px;
        height: auto;
      }
  }


}
  .rett {
    right: 0;
    top: -10%;
    margin: auto;

    @include breakpoint(md) {
      right: 10%;
    }
    @include breakpoint(lg) {
      right: 20%;
    }
  }
  .ellipse {
    height: 300px;

    @include breakpoint(sm) {
      height: auto;
    }
  }
  .dive_in {
    width: 150px;
    top: -100px;
    right: 0;

    @include breakpoint(sm) {
      top: -55px;
      width: auto;
    }
  }
  .circle_line {
    right: auto;
    left: 0;
    transform: rotate(180deg);

    @include breakpoint(md) {
      left: auto;
      top: 0;
      right: 0;
      transform: none;
    }
  }
  .moheato {
    left: 30px;
  }
  .moheato_mobile {
    left: 0;
    right: 0;
    margin: auto;
  }

//  week list
  .d8-week-list {
    border-radius: 0;
    box-shadow: none;

    .d8-u-contrast-lower-bg {
      background-color: #fff;
    }

    >.d8-c-collapse.no-border {
      border: 1px solid #000;
      border-radius: 0;
      margin-bottom: 10px;
      box-shadow: var(--shadow-md);
    }

    .week-list header.is-open {
      background-color: #EB636F;
      border-radius: 0px;
      color: #fff;

      &:after{
        color: #fff;
      }

      svg {
        path, g {
          stroke: #fff;
        }
      }
    }
  }




}