@import url("https://use.typekit.net/cah4xqg.css");

.bbyl-2023 {
  --color-super-dark-blue: rgba(10,37,68, 1);
  background-color: var(--color-super-dark-blue);
  --color-secondary: #00D6C6;
  --color-error: #FF4858;
  h1,
  h2,
  .scrolling-banner,
  .quote-section {
    font-family: bebas-neue, sans-serif;
    font-weight: 100;
    line-height: 1;
  }

  .tyw-logo-svg {
    font-size: 230px;

    @include breakpoint(md) {
      font-size: 285px;
    }
  }

  .d8-c-hero {
    height: 100%;
  }

  .hero-content {
    min-height: 50vh;
    text-align: left;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-bottom: -1px;

    @include breakpoint(md) {
      display: block;
      min-height: 50vh;
    }

    .heading-wrapper {
      background: linear-gradient(to top, rgba(10,37,68, 1) 0%, rgba(10,37,68, 0));
      .desktop-text {
        display: none;
      }
      @include breakpoint(sm) {
        .desktop-text {
          display: block;
        }
        background: linear-gradient(to top, rgba(10,37,68, 1) 20%, rgba(10,37,68, 0));
      }
      h1 {
        font-size: 81px;
        display: inline-block;
        @include breakpoint(sm) {
          font-size: 156px;
          display: block;
        }

        @include breakpoint(lg) {
          font-size: 214px;
        }
      }
    }
  }

  .custom-large-h2 {
    font-size: 47px;
    svg {
      width: 47px;
      height: auto;
    }
    @include breakpoint(md) {
      font-size: 100px;

      svg {
        width: 100px;
        height: auto;
      }
    }
  }

  .extra-line-height {
    line-height: 2.5;
  }

  .relive-the-feeling {
    background-color: white;
    @include breakpoint(sm, "(orientation: portrait)") {
      // padding-top: var(--space-xxl);
    }

    @media (min-width: 1800px) {
      // padding-top: 70px;
      // padding-bottom: 140px;
    }

    // .welcome-to-the-best-week-svg__wrapper {

    //   .welcome-to-the-best-week-svg {
    //     right: 0;
    //     bottom: -10%;
    //     overflow: hidden;
    //     margin-right: var(--space-md);
    //     @include breakpoint(md) {
    //       margin-right: 0;
    //     }
    //     svg {
    //       position: relative;
    //       width: 160px;
    //       height: auto;
    //       @include breakpoint(md) {
    //         width: 315px;
    //         right: -15%;
    //       }
    //       @include breakpoint(lg) {
    //         width: 415px;
    //       }
    //     }
    //   }
    // }
  }

  .where-to__wrapper {
    background-image: url(https://cdn.theyachtweek.com/assets/sign-up-2023/white-swish.svg);
    background-position: center -1px;
    .welcome-to-the-best-week-svg__wrapper {

      .welcome-to-the-best-week-svg {
        right: 0;
        // bottom: -10%;
        overflow: hidden;
        margin-right: var(--space-md);
        @include breakpoint(md) {
          transform: translateY(-100%);
          margin-right: 0;
        }
        svg {
          position: relative;
          width: 160px;
          height: auto;
          @include breakpoint(md) {
            width: 315px;
            // right: -15%;
          }
          @include breakpoint(lg) {
            width: 415px;
          }
        }
      }
    }
    @include breakpoint(md) {
      .push-down {
        margin-top: 100px;
      }
      background-position: bottom;
    }
  }

  // .myth-busting {
  //   overflow: hidden;

  //   .bullet-points {
  //     .odd {
  //       transform: translateX(-10%);

  //       @include breakpoint(sm, "(orientation: portrait)") {
  //         transform: translateX(-5%);
  //       }

  //       @include breakpoint(md) {
  //         transform: none;
  //       }
  //     }

  //     .even {
  //       transform: translateX(10%);

  //       @include breakpoint(sm, "(orientation: portrait)") {
  //         transform: translateX(5%);
  //       }

  //       @include breakpoint(md) {
  //         transform: none;
  //       }
  //     }
  //   }
  // }

  .itinerary-section {
    background-color: rgba(10,37,68, 1);
    .google-map {
      width: calc(100% + 2 * var(--component-padding));
      margin-left: calc(-1 * var(--component-padding));
      @include breakpoint(md) {
        width: inherit;
        margin-left: inherit;
      }
    }
    .google-map__wrapper {
      overflow: hidden;
      @include breakpoint(md) {
        border-radius: 4rem;
      }
    }
  }
  .blogs-section {
    .divider {
      height: 21px;
      background-color: var(--color-primary);
      display: none;
      @include breakpoint(md) {
        display: block;
      }
    }
    .d8-c-card-slider {
      h3, p {
        color: var(--color-white);
      }
    }
  }

  .quote-section {
    background-image: url(https://cdn.theyachtweek.com/assets/bbyl-2023/fg_bg.jpg);
   .quote-section__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(37, 28, 10, 0.6);
    }
    .quote__wrapper {
      span {
        display: block;
        position: relative;
        color: var(--color-white);
        font-size: 47px;
        line-height: 47px;
        &:first-child {
          height: 25px;
        }
        &:last-child {
          height: 25px;
        }
          @include breakpoint(sm) {
            font-size: 97px;
            line-height: 97px;
            &:first-child {
              height: 50px;
            }
            &:last-child {
              height: 50px;
            }
          }
      }
    }
  }

  .scrolling-banner {
    height: 81px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    position: absolute;
    bottom: 0;
    // background-color: rgba(#ff4858, 1);
    z-index: -1;

    &:hover div {
      animation-play-state: paused;
    }

    div {
      display: flex;
      position: relative;
      animation: marquee 80s linear infinite;
      justify-content: space-around;
    }

    p {
      display: block;
      height: 81px;
      font-size: 81px;
      min-width: 630px;
      line-height: 81px;
      margin: 0 1vw;
      text-transform: uppercase;
      color: var(--color-secondary);
    }

    // &.netweek {
    //   div {
    //     animation: marquee 60s linear infinite;
    //   }

    //   p {
    //     min-width: 535px;
    //   }
    // }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    @include breakpoint(sm) {
      height: 156px;
      p {
        height: 156px;
        font-size: 156px;
        line-height: 156px;
        min-width: 1210px;
      }

    }
    @include breakpoint(lg) {
      height: 214px;

      p {
        font-size: 214px;
        height: 214px;
        line-height: 214px;
        min-width: 1660px;
      }
    }
  }

  .rotating {
    -webkit-animation: rotating 10s linear infinite;
    -moz-animation: rotating 10s linear infinite;
    -ms-animation: rotating 10s linear infinite;
    -o-animation: rotating 10s linear infinite;
    animation: rotating 10s linear infinite;
  }

  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-100%);
    }
  }


  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      transform-origin: center;
    }

    to {
      -ms-transform: rotate(-360deg);
      -moz-transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
      -o-transform: rotate(-360deg);
      transform: rotate(-360deg);
      transform-origin: center;
    }
  }

  .vertical-align-middle {
    vertical-align: middle;
  }
}