.lead-booker {
    .body-content {
        --color-black: #122F51;

        color: var(--color-black);
    }

    .lead-booker-hero {
        &-image {
            object-fit: cover;
            z-index: -1;
            width: 100%;
        }
        &-content {
            height: auto;
            min-height: 90vh;
            color: var(--color-white);

            h1, h2, h3 {
                color: var(--color-white);
            }
        }
        &-icon {
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translate(-50%, 15%);
            @include breakpoint(md) {
                width: 300px;
                height: 300px;
            }
            @include breakpoint(lg) {
                transform: translate(-50%, 20%);
                width: 400px;
                height: 400px;
            }
        }
        &-logo {
            @include breakpoint(lg) {
                position: absolute;
                left: 50%;
                top: var(--space-md);
                transform: translateX(-50%);
            }
            &-svg {
                width: 400px;
                height: auto;
                max-width: 80%;
            }
        }
        &-download {
            background-color: #122F51;
            border-color: #122F51;
            border-radius: 0;
            font-family: var(--font-secondary);
            text-transform: uppercase;
        }
    }

    button[type="submit"] {
        min-width: 200px;
    }
}
.tear-overlap {
    margin-bottom: -2px;
}