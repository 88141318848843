.destination-drumcode {
    --color-bg: #196079;
    --color-primary: #DF8627;
    --color-black: hsl(240, 24%, 96%);
    --color-white: hsl(0, 0%, 15%);
    // color contrasts
    --color-bg: #196079;
    --color-contrast-lower: hsl(240, 8%, 12%);
    --color-contrast-low: hsl(240, 4%, 20%);
    --color-contrast-medium: hsl(240, 1%, 48%);
    --color-contrast-high: hsl(240, 1%, 83%);
    --color-contrast-higher: hsl(0, 0%, 95%);

    .d8-u-color-white.text-xxxl,
    .d8-c-hero .hero--basic .basic-content__wrapper .subtitle,
    .d8-c-card .d8-u-color-white {
        color: var(--color-black)
    }
    .subtitle {
        color: var(--color-black)
    }
    .d8-c-navbar, .mobile--menu__top {
        background: var(--color-contrast-low);
        .logo {
            svg {
                path,
                g {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
        }
        .d8-u-primary-bg-low-bg {
          color: var(--color-white);
          svg {
              path,
              g {
                  stroke: var(--color-white);
              }
          }
        }
        .d8-c-collapse-original {
            background-color: var(--color-contrast-low);
        }
    }
    #tyw-c-weekly-tabs-wrapper{
        .d8-u-contrast-lower-bg {
            padding: var(--component-padding)!important;
        }
        .d8-week-list {
            .d8-c-collapse {
                .week-list {
                    .d8-week-row-img {
                        .d8-week-row-gradient {
                            background: linear-gradient(-270deg, hsla(0, 0%, 15, 0.1), hsla(0, 0%, 15, 1));
                        }
                    }
                }
            }
        }
    }
    .d8-c-card-slider {
        .card-slider-controls {
            &.right {
                background: none;
            }
            &.left {
                background: none;
            }
        }
    }
    .d8-c-footer {
        .logo {
            svg {
                g {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
        }
        .footer--content {
            .btn--ghost {
                background-color: var(--color-black);
                color: var(--color-white);
            }
        }
        .partners-links {
            .d8-svg-qd-logo,
            .d8-svg-tsw-logo,
            .d8-svg-ohso-logo {
                g,
                path {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
            .d8-svg-abta-logo {
                g,
                path,
                polygon {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
            .d8-svg-ynf-logo {
                g,
                path {
                    // fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
        }
    }
    .cookie-banner {
        background: var(--color-primary);
    }

}
