@import url("https://use.typekit.net/iqm2pom.css");
.destination-ultra {
    // typography
    --font-primary: poppins, sans-serif;

    --color-primary-darker: hsl(345, 100%, 60%);
    --color-primary-dark: hsl(345, 100%, 50%);
    --color-primary: hsl(345, 100%, 60%);
    --color-primary-light: hsl(345, 100%, 70%);
    --color-primary-lighter: hsl(345, 100%, 80%);
    --color-secondary-darker: hsl(345, 100%, 60%);
    --color-secondary-dark: hsl(345, 100%, 50%);
    --color-secondary: hsl(345, 100%, 60%);
    --color-secondary-light: hsl(345, 100%, 70%);
    --color-secondary-lighter: hsl(345, 100%, 80%);

    --color-success-darker: hsl(345, 100%, 60%);
    --color-success-dark: hsl(345, 100%, 50%);
    --color-success: hsl(345, 100%, 60%);
    --color-success-light: hsl(345, 100%, 70%);
    --color-success-lighter: hsl(345, 100%, 80%);
    --color-error-darker: hsl(345, 100%, 60%);
    --color-error-dark: hsl(345, 100%, 50%);
    --color-error: hsl(345, 100%, 60%);
    --color-error-light: hsl(345, 100%, 70%);
    --color-error-lighter: hsl(345, 100%, 80%);

    --color-black: hsl(240, 24%, 96%);
    --color-white: hsl(0, 0%, 15%);
    // color contrasts
    --color-bg: hsl(0, 0%, 14%);
    --color-contrast-lower: hsl(240, 8%, 12%);
    --color-contrast-low: hsl(240, 4%, 20%);
    --color-contrast-medium: hsl(240, 1%, 48%);
    --color-contrast-high: hsl(240, 1%, 83%);
    --color-contrast-higher: hsl(0, 0%, 95%);

    --color-extra: #FF3D31;
    h1,
    h2,
    h3,
    h4,
    h5,
    .d8-u-text-bold {
        font-weight: 700;
    }
    .d8-u-color-white {
        color: var(--color-black);
    }

    .hero--video {
        .d8-c-card {
            background-color: transparent !important;
            .d8-u-color-white {
                color: var(--color-black);
            }
        }
    }

    .d8-gallery-modal-wrap {
        svg {
            path,
            g {
                fill: var(--color-black);
            }
        }
        p {
            color: var(--color-black);
        }
    }

    .d8-c-navbar, .mobile--menu__top {
        background: var(--color-contrast-low);
        .logo {
            svg {
                path,
                g {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
        }
        .d8-u-primary-bg-low-bg {
          color: var(--color-white);
          svg {
              path,
              g {
                  stroke: var(--color-white);
              }
          }
        }
        .d8-c-collapse-original {
            background-color: var(--color-contrast-low);
        }
    }
    .d8-c-sticky-navbar {
        background: var(--color-contrast-low);
    }
    .d8-c-phone-number {
        svg {
            path,
            g {
                fill: var(--color-black);
                stroke: var(--color-black);
            }
        }
        p {
            color: var(--color-black);
        }
    }
    .d8-u-contrast-lower-bg {
        background-color: #393939;
    }
    .d8-c-card {
        background-color: #393939;
        .d8-u-color-white {
            color: var(--color-black);
        }
    }
    .d8-c-card-slider {
        .card-slider-controls {
            &.right {
                background: linear-gradient(-90deg, rgba(38, 38, 38, 1), rgba(38, 38, 38, 0));
            }
            &.left {
                left: 0;
                background: linear-gradient(-90deg, rgba(38, 38, 38, 0), rgba(38, 38, 38, 1));
            }
        }
    }
    .d8-c-input {
        .form-control {
            background-color: var(--color-black);
        }
    }
    .tyw-c-intro {
        svg {
            path,
            g {
                stroke: var(--color-primary);
            }
        }
    }
    .d8-c-destination-map {
        .d8-c-card-slider {
            .card-slider-controls {
                &.right {
                    background: none;
                }
                &.left {
                    background: none;
                }
            }
        }
        .map-card{
            .d8-svg-location-bold{
                path,
                g {
                    fill: var(--color-primary);
                }
            }
        }
    }
    #tyw-c-weekly-tabs-wrapper{
        .d8-u-contrast-lower-bg {
            padding: var(--component-padding)!important;
        }
        .d8-week-list {
            .d8-c-collapse {
                &.week-list {
                    header {
                        &.is-open {
                            background-color: var(--color-white);
                        }
                    }
                    .d8-week-row-img {
                        .d8-week-row-gradient {
                            background: linear-gradient(-270deg, hsla(0, 0%, 15, 0.1), hsla(0, 0%, 15, 1))
                        }
                    }
                }
            }
        }
    }
    .d8-c-footer {
        .logo {
            svg {
                g {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
        }
        .footer--content {
            .d8-svg-facebook-brand-2 {
                path:first-child {
                    fill: transparent;
                }
            }
            .btn--ghost {
                background-color: var(--color-black);
                color: var(--color-white);
            }
        }
        .partners-links {
            .d8-svg-qd-logo,
            .d8-svg-tsw-logo,
            .d8-svg-ohso-logo {
                g,
                path {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
            .d8-svg-abta-logo {
                g,
                path,
                polygon {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
            .d8-svg-ynf-logo {
                g,
                path {
                    // fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
        }
    }
    .cookie-banner {
        background: var(--color-primary);
    }
    .pill--purple-outline {
      background-color: transparent;
      color: #fff;
      border: 1px solid var(--color-extra);
      svg {
          path,
          g {
              stroke: var(--color-extra);
          }
      }
    }
    .d8-l-half-grid {
      .d8-c-avatar {
        background: var(--color-primary)!important;
      }

      svg {
          path,
          g {
              stroke: var(--color-primary)!important;
          }
      }
    }
}
