.tyw-why-page {
    .text-xxxxl {
        font-size: var(--text-xxl);
        @include breakpoint(sm) {
            font-size: var(--text-xxxxl);
        }
    }
}

.scrolling-banner {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    top: 0;
    z-index: 5;
    &:hover div {
      animation-play-state: paused;
    }

    div {
      display: flex;
      position: relative;
      animation: marquee 120s linear infinite;
      justify-content: space-around;
    }

    p {
        font-family: var(--font-secondary);
        font-weight: bold;
        display: block;
        height: 100px;
        min-width: 1300px;
        line-height: 100px;
        font-size: 80px;
        margin: 0 1vw;
        text-transform: uppercase;
    }
    &.long {
        p {
            min-width: 2410px;
        }
    }

    &.timeout {
        p {
            min-width: 1900px;
        }
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }