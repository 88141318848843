
@import url("https://use.typekit.net/boi4piw.css");

.christmas {
  --color-bg: #fff;
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 100%;
  --color-secondary: #fc4858;
  --color-secondary-dark:#e93f4e;
  --color-secondary-darker: #db3e4c;
  .d8-u-color-secondary {
    color: var(--color-secondary);
  }
  .hero--basic {
    height: 411px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .ice-border {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url(https://assets.theyachtweek.com/wp-content/uploads/2021/11/ice.png);
      background-position: bottom center;
      background-size: cover;
    }
    .text-xxxl {
      text-transform: uppercase;
      font-size: 135px;
      letter-spacing: -2px;
      line-height: 85%;
      font-family: Tungsten;
      font-family: anton, sans-serif;
      font-weight: 400;
      font-style: normal;
      color:#fff;
      @media only screen and (max-width: 800px) {
        font-size: 90px;
        line-height: 100%;
        margin-bottom: 43px;
      }
    }
  }
  .red-bow {
    width: 100%;
    height: 134px;
    background-size: auto 100%;
    background-position: center;
    background-repeat: repeat-x;
    position: relative;
    transform: translateY(-41px);
    margin-bottom: -30px;
  }
  .tyw-logo-svg {
    width: 240px;
    height: 180px;
    position: relative;
    top: -66px;
    @media only screen and (max-width: 800px) {
      width: 200px;
      height: 180px;
      top: -10px;
    }
  }
  .background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .footer_decoration {
    height: 172px;
    width: 100%;
    margin-bottom: -2px;
    position: relative;
  }
  footer {
    position: relative;
  }
  .signup-footer-wrapper {
    background-image: url(https://cdn.theyachtweek.com/assets/icons/footer_rect_1.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}
  @-webkit-keyframes snowflakes-grow{0%{font-size:1em}25%{font-size:2em}50%{font-size:1em}75%{font-size:2em}100%{font-size:1em}}
  @-webkit-keyframes snowflakes-shake{0%,100%{margin-left: 0}50%{margin-left: -120px}}

  @keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}
  @keyframes snowflakes-grow{0%{font-size:1em}25%{font-size:1.5em}50%{font-size:0.8em}75%{font-size:1.3em}100%{font-size:1em}}
  @keyframes snowflakes-shake{0%,100%{margin-left: 0}50%{margin-left: -120px}}

  .snowflake {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1em;
    font-family: Arial, sans-serif;
    text-shadow: 0 0 2px rgba(25, 46, 43, 0.4);
    position:fixed;
    top:-10%;
    z-index:9999;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    cursor:default;
    -webkit-animation-name:snowflakes-fall,snowflakes-shake,snowflakes-grow;
    -webkit-animation-timing-function:linear,ease-in-out;
    -webkit-animation-iteration-count:infinite,infinite;
    -webkit-animation-play-state:running,running;
    animation-name:snowflakes-fall,snowflakes-shake,snowflakes-grow;
    animation-timing-function:linear,ease-in-out;
    animation-iteration-count:infinite,infinite;
    animation-play-state:running,running;
  }
  .snowflake:nth-of-type(1){
    left:10%;
    -webkit-animation-delay:1s,1s,.5s;
    animation-delay:1s,1s,.5s;
    -webkit-animation-duration:9s,3s,10s;
    animation-duration:9s,3s,10s;
  }
  .snowflake:nth-of-type(2){
    left:25%;
    -webkit-animation-delay:6s,.5s,1.5s;
    animation-delay:6s,.5s,1.5s;
    -webkit-animation-duration:11s,3s,10s;
    animation-duration:11s,3s,10s;
  }
  .snowflake:nth-of-type(3){
    left:50%;
    -webkit-animation-delay:4s,2s,1s;
    animation-delay:4s,2s,1s;
    -webkit-animation-duration:8s,3s,10s;
    animation-duration:8s,3s,10s;
  }
  .snowflake:nth-of-type(4){
    left:75%;
    -webkit-animation-delay:2s,2s,3s;
    animation-delay:2s,2s,3s;
    -webkit-animation-duration:7.5s,3s,10s;
    animation-duration:7.5s,3s,10s;
  }
  .snowflake:nth-of-type(5){
    left:90%;
    -webkit-animation-delay:8s,3s,2s;
    animation-delay:8s,3s,2s;
    -webkit-animation-duration:8.5s,3s,10s;
    animation-duration:8.5s,3s,10s;
  }
}