.partners-svg-featured-image {
    align-self: center;

    width: calc(100% + (var(--component-padding) * 1));

    img {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    @include breakpoint(sm) {
        width: 100%;

        img {
            border-top-right-radius: var(--radius-md);
            border-bottom-right-radius: var(--radius-md);
        }
    }

    .svg-featured-image-box {
        width: 40%;
        top: 28%;
        height: 60%;
    }

    .svg-featured-image-stripes {
        width: 45%;
        top: 28%;
        left: 16px;
        height: 60%;
    }

    .svg-featured-image-dots {
        width: 57%;
    }

    &.partners-image-with-content {
        max-width: 75%;
    }
}

.hide-mobile {
    display: none;
    @include breakpoint(sm) {
        display: block;
    }
}

.hide-desktop {
    display: block;
    @include breakpoint(sm) {
        display: none;
    }
}

.partners-featured-text {
    top: 0;
    max-width:unset;
    margin-left: calc(var(--component-padding) * -1);
    width: 100%;

    @include breakpoint(sm){
        top: 15%;
        max-width: var(--max-width-xxxxs);
    }

    @media (max-width: 48rem){
        .d8-c-card {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .down-arrow {
        z-index: 2;
        margin-right: calc(var(--component-padding) * -1);
    }
}

.line-through-heading {
    @include breakpoint(sm) {
        .center-line {
            height: 1px;
            background-color: var(--color-primary);
        }

        .line-through-heading-h {
            background-color: #fff;
            z-index: 2;
        }
    }
}

.partners-carousel {
    .slick-list {
        overflow: visible !important;
    }

    .slick-disabled{
        opacity: 0;
    }

    .d8-c-card-slider .slider--row .slider--row__wrapper .d8-c-card.slider-inner-card {
        flex: unset;
        max-width: unset;
        margin: 0;
    }
}

.object-contain-aspect {
    object-fit: contain !important;
}

.partners-hero {
    .d8-c-hero {
        padding-bottom: var(--space-xl);

        @include breakpoint(sm) {
            padding-bottom: 0;
        }
    }
}

.absolute-desktop {
    @include breakpoint(sm) {
        position: absolute;
    }
}