/* globals.css */
[data-theme="cash-money"] {
  /* Font Variables */
  --dui-font-heading: 'Anton', sans-serif;
  --dui-font-subheading: 'Antonio', sans-serif;
  --dui-font-body: 'Inter', sans-serif;

  /* Default color variables */
  --dui-color-white: #000;
  --dui-color-black: #fff;

  /* Handle old colors from day8-react */
  --color-white: #000;
  --color-black: #fff;
  --color-bg: hsl(0, 0%, 14%);
  --color-contrast-lower: hsl(240, 8%, 12%);
  --color-contrast-low: hsl(240, 4%, 20%);
  --color-contrast-medium: hsl(240, 1%, 48%);
  --color-contrast-high: #fff;
  --color-contrast-higher: hsl(0, 0%, 95%);
  --color-primary: hsl(0, 100%, 32.9%);
  /* hsla(0, 100%, 32.9%, 0.8) */
  /* Primary Color Variables */
  --dui-color-primary-950: #fff;
  --dui-color-primary-900: hsl(0, 100%, 32.9%);
  --dui-color-primary-800: hsla(0, 100%, 32.9%, 0.8);
  --dui-color-primary-700: hsl(0, 100%, 32.9%);
  --dui-color-primary-600: hsl(0, 100%, 32.9%);
  --dui-color-primary-500: hsl(0, 100%, 32.9%);
  --dui-color-primary-400: hsl(0, 100%, 32.9%);
  --dui-color-primary-300: hsl(0, 100%, 32.9%);
  --dui-color-primary-200: hsl(0, 100%, 32.9%);
  --dui-color-primary-100: #111;
  --dui-color-primary-50: hsl(0, 100%, 32.9%);

  /* Secondary Color Variables */
  --dui-color-secondary-900:  #111;
  --dui-color-secondary-800: #111;
  --dui-color-secondary-700:  #111;
  --dui-color-secondary-600:  #111;
  --dui-color-secondary-500:  #444;
  --dui-color-secondary-400:  #111;
  --dui-color-secondary-300:  #111;
  --dui-color-secondary-200:  #111;
  --dui-color-secondary-100:  #111;
  --dui-color-secondary-50: #111;

  /* Primary-Dark Color Variables */
  --dui-color-primary-dark-900: #bdc5ce;
  --dui-color-primary-dark-800: #bdc5ce;
  --dui-color-primary-dark-700: #aab4c0;
  --dui-color-primary-dark-600: #8493a5;
  --dui-color-primary-dark-500: #5e7289;
  --dui-color-primary-dark-400: #38506d;
  --dui-color-primary-dark-300: #0f2744;
  --dui-color-primary-dark-200: #0c2037;
  --dui-color-primary-dark-100: #09182a;
  --dui-color-primary-dark-50: #06111d;

  /* Additional Color Variables (Sunny Yellow, Peach, Dusty Rose, Bright Blue, Dark) */
  /* sunny-yellow Color Variables */
  --dui-color-sunny-yellow-900: #111;
  --dui-color-sunny-yellow-800: #111;
  --dui-color-sunny-yellow-700: #111;
  --dui-color-sunny-yellow-600: #111;
  --dui-color-sunny-yellow-500: #111;
  --dui-color-sunny-yellow-400: #111;
  --dui-color-sunny-yellow-300: #111;
  --dui-color-sunny-yellow-200: #111;
  --dui-color-sunny-yellow-100: #111;
  --dui-color-sunny-yellow-50: #111;

  /* peach Color Variables */
  --dui-color-peach-900: #fae9db;
  --dui-color-peach-800: #fef9f5;
  --dui-color-peach-700: #fdf7f2;
  --dui-color-peach-600: #fcf4ec;
  --dui-color-peach-500: #fcf0e7;
  --dui-color-peach-400: #fbede1;
  --dui-color-peach-300: #d2c4b8;
  --dui-color-peach-200: #aa9e95;
  --dui-color-peach-100: #827972;
  --dui-color-peach-50: #5a544f;

  /* dusty-rose Color Variables */
  --dui-color-dusty-rose-900: #111;
  --dui-color-dusty-rose-800: #111;
  --dui-color-dusty-rose-700: #111;
  --dui-color-dusty-rose-600: #111;
  --dui-color-dusty-rose-500: #444;
  --dui-color-dusty-rose-400: #111;
  --dui-color-dusty-rose-300: #111;
  --dui-color-dusty-rose-200: #111;
  --dui-color-dusty-rose-100: #111;
  --dui-color-dusty-rose-50: #111;

  /* bright-blue Color Variables */
  --dui-color-bright-blue-900: #f1d7e9;
  --dui-color-bright-blue-800: #fbf4f9;
  --dui-color-bright-blue-700: #faf1f7;
  --dui-color-bright-blue-600: #f8eaf4;
  --dui-color-bright-blue-500: #f5e4f0;
  --dui-color-bright-blue-400: #f3dded;
  --dui-color-bright-blue-300: #cab5c4;
  --dui-color-bright-blue-200: #a4929e;
  --dui-color-bright-blue-100: #7d7079;
  --dui-color-bright-blue-50: #574d54;

  /* dark Color Variables */
  --dui-color-dark-800: #111928;
  --dui-color-dark-700: #1f2a37;
  --dui-color-dark-600: #374151;
  --dui-color-dark-500: #4b5563;
  --dui-color-dark-400: #6b7280;
  --dui-color-dark-300: #9ca3af;
  --dui-color-dark-200: #d1d5db;
  --dui-color-dark-100: #e5e7eb;
  /* (Add similar CSS variable declarations for other color palettes as shown above) */

  .dui-u-custom-theme-invert, input, select, .dui-bg-primary-900 {
    --dui-color-black: #000;
    --dui-color-white: #fff;
    --dui-color-primary-950: #000;
    --color-contrast-high: #000;
  }
  button.dui-text-primary-900:hover {
    color: var(--dui-color-black);
  }
  .dui-border-primary-950 {
    border: none;
    background: var(--dui-color-secondary-900);
  }
  .d8-c-card {
    border: none !important;
    background: var(--dui-color-secondary-900);
  }
  input, select {
    background: transparent;
    border-color: var(--dui-color-primary-900);
    outline-color: var(--dui-color-primary-900);
    color: white;
  }
  input:focus, select:focus {
    border-color: var(--dui-color-primary-800);
    outline-color: var(--dui-color-primary-800);
  }
}