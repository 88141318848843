.d8-c-card-divider {
    margin-left: calc(var(--component-padding) * -1);
    padding-left: var(--component-padding);
    padding-right: var(--component-padding);
    padding-top: var(--space-xxxs);
    padding-bottom: var(--space-xxxs);
    width: calc(100% + (var(--component-padding) * 2));
}

.d8-u-text-transform-none {
    text-transform: none;
}

.d8-u-block-align-right {
    display: block;
    margin-right: 0;
    margin-left: auto;
}

.flip {
    transform: scaleX(-1);
}

.d8-u-background-dark-blue {
    background-color: #122F51;
}

.d8-u-background-pale-blue {
    background-color: #BED4E4;
}

.d8-u-color-yellow {
    color: #DEE254;
}

.d8-u-margin-tuck-up-lg, .d8-u-margin-tuck-up-xl, .d8-u-margin-tuck-up-xxl, .d8-u-margin-tuck-up-xxxl, .d8-u-margin-tuck-up-xxxxl {
    margin-top: calc(var(--component-padding) * -2);
}

 @include breakpoint(md) {
    
    .d8-u-margin-tuck-up-md {
        margin-top: calc(var(--component-padding) * -1);
    }
    .d8-u-margin-tuck-up-lg {
        margin-top: calc(var(--component-padding) * -2);
    }
    .d8-u-margin-tuck-up-xl {
        margin-top: calc(var(--component-padding) * -3);
    }
    .d8-u-margin-tuck-up-xxl {
        margin-top: calc(var(--component-padding) * -4);
    }
    .d8-u-margin-tuck-up-xxxl {
        margin-top: calc(var(--component-padding) * -8);
    }
    .d8-u-margin-tuck-up-xxxxl {
        margin-top: calc(var(--component-padding) * -12);
    }

    .d8-u-margin-push-left-md {
        margin-left: 5%;
    }
    .d8-u-margin-push-left-lg {
        margin-left: 10%;
    }
    .d8-u-margin-push-left-xl {
        margin-left: 15%;
    }
    .d8-u-margin-push-left-xxl {
        margin-left: 20%;
    }
    .d8-u-margin-push-left-xxxl {
        margin-left: 30%;
    }
    .d8-u-margin-push-left-xxxxl {
        margin-left: 35%;
    }

}

.circle-img-padding {
    @include breakpoint(md) {
        margin-left: 20%;
    }

    @include breakpoint(xl) {
        margin-left: 30%;
    }
}

.solo-image {
    height: 40vh;
    object-fit: cover;
    object-position: center center;
}

.content-sideline {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 15px;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 4px;

    &-circle {
        height: 15px;
        width: 15px;
        border: 1px solid #DEE254 ;
        border-radius: 50%;
    }

    &-line {
        height: 100%;
        width: 1px;
        background-color: #DEE254;
    }
}

.d8-svg-divider {
    display: block
}

.shape-background {
    z-index:-1;
    display: none;

    @include breakpoint(sm) {
        display: initial;
    }
}


.custom-icon-1, .custom-icon-2 {
    position: absolute;
    top: 0;
    right: -80px;
    font-size: 40vw;

    display: none;

    @include breakpoint(sm) {
        display: initial;
    }
}

.reviews-cabins {
    h1, h2 {
        @extend .text-xxxxl;
    }
}