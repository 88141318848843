body.landing-2023 {
    background-color: none;

    h3,
    h4 {
        font-family: bebas-neue, sans-serif;
        font-weight: 100;
        line-height: 1;
    }

    .d8-c-navbar.--transparent {
        height: 0;
    }


    .hero-content {
        justify-content: center;

        .title-section {
            justify-content: space-between;
            flex-direction: column;

            h1 {
                width: 690px;
                text-align: left;
                max-width: 100%;

                @include breakpoint(md) {
                    width: inherit;
                }

                &.heading-v2 {
                    @include breakpoint(md) {
                        font-size: 160px;
                    }
                }
            }

            @include breakpoint(md) {
                flex-direction: row;
            }
        }
    }

    .desktop-swish-svg {
        svg {
            max-width: 70%;
        }
    }

    .d8-u-card-no-padding {
        padding: 0;
        margin: 0;
    }

    .d8-c-hero {
        .hero--video {
            .hero--video-wrap {
                .video {
                    height: 100% !important;
                }
            }
        }
    }

    .react-datetime-picker__calendar {
        z-index: 10;
    }

    .hero-content {
        .exotics-hero-text {

            font-size: 85px;

            @include breakpoint(xs) {
                font-size: 105px;
            }

            @include breakpoint(sm) {
                font-size: 120px;
            }

            @include breakpoint(md) {
                font-size: 140px;
            }

            @include breakpoint(lg) {
                font-size: 160px;
            }

            @include breakpoint(xl) {
                font-size: 180px;
            }
        }
    }
}

/* Removing calendly logo */
.booking-container>a {
    display: none;
}

.min-height-100vh {
    min-height: 100vh;
}