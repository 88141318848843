.destination-greece-applebum {
    --color-primary: #8167ff;
    --color-bg: #ffa8d7;
    --color-contrast-high: #333333;

    .d8-c-navbar {
        background-color: #ffa8d7;
    }

    .d8-u-contrast-low-bg {
        background-color: #8167ff;

        h2 {
            color: #fff;
        }
    }

    .d8-c-card-slider {
        .card-slider-controls {
            &.right {
                background: none;
            }

            &.left {
                left: 0;
                background: none;
            }
        }
    }

    .d8-c-footer {
        .footer--content {
            .btn--ghost {
                --color-contrast-medium: #333333;
            }
        }
    }
}