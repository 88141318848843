.custom-dropdown__wrapper {
  position: relative;
  input,
  i {
    cursor: pointer;
  }
  .dropdown-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    margin-top: -5px;
    transition: all 0.2s ease;
    color: $gray;
    &.flip {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .custom-dropdown {
    position: absolute;
    background: #fff;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.2s ease;
    z-index: 3;
    max-height: 288px;
    overflow: auto;
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        padding: 10px;
        cursor: pointer;
        &:hover {
          background: $navy;
          color: #fff;
        }
      }
    }
  }
}
