.destination-hip-hop {
    --color-flame: #E7520D;
    --color-jasmine: #FFE499;
    --color-bg: var(--color-flame);
    --color-primary: var(--color-jasmine);
    --color-primary-darker: #cdb779;
    --color-primary-dark: #e5cd88;
    // --color-contrast-high: hsl(0, 0%, 15%);
    // --color-contrast-medium: hsl(0, 0%, 15%);
    // --color-contrast-high: hsl(240, 24%, 96%);
    // --color-black: hsl(0, 0%, 15%);
    // --color-white: hsl(240, 24%, 96%);
    color: var(--color-white);

    h1 {
        color: var(--color-jasmine);
    }

    h2,
    h3,
    h4,
    h5 {
        color: var(--color-white);
    }

    .d8-c-navbar {
        .mobile--menu {
            background-color: var(--color-jasmine);
        }

        .navbar--mobile {
            .d8-c-collapse-original {
                background-color: var(--color-white);
                color: var(--color-black);
            }
            .d8-c-collapse-mobile-nav .d8-c-collapse-original header.is-open::after {
                color: var(--color-bg);
            }
            .mobile-link-primary {
                background-color: var(--color-bg);
            }
        }
    }

    .ReactModal__Content,
    .d8-c-phone-number,
    .d8-u-white-bg {
        color: var(--color-black);

        h2,
        h3,
        h4,
        h5 {
            color: var(--color-black);
        }

        .d8-u-color-primary {
            color: var(--color-primary);
        }

        .image--banner {
            .card--image__heading {
                color: var(--color-primary);
            }
        }
    }

    .btn--primary,
    .btn--circle-icon-primary {
        color: var(--color-contrast-higher);
    }

    .btn--underline {
        color: var(--color-white);
    }

    .d8-c-card-slider {
        .card-slider-controls {
            &.right {
                background: none;
            }

            &.left {
                background: none;
            }
        }
    }

    .d8-u-contrast-low-bg {
        background-color: var(--color-primary);

        h2 {
            color: var(--color-contrast-higher);
        }
    }

    .d8-c-card-slider {
        .d8-u-color-black {
            color: var(--color-white);
        }
    }

    .d8-c-footer {
        .logo {
            svg {

                path,
                g {
                    fill: var(--color-white);
                    stroke: var(--color-white);
                }
            }
        }

        .d8-u-color-contrast-high {
            color: var(--color-white);
        }

        .partners-links {

            .d8-svg-aegean-logo,
            .d8-svg-qd-logo,
            .d8-svg-tsw-logo {
                path {
                    fill: var(--color-white);
                }
            }

            .d8-svg-ynf-logo {

                g,
                path {
                    fill: var(--color-black);
                    stroke: var(--color-white);
                }
            }
        }

        .footer--content {

            .d8-svg-facebook-brand-2 {
                path:first-child {
                    fill: transparent;
                }
            }

            .icon.icon--md:not(.d8-svg-facebook-brand-2) {
                path {
                    stroke: var(--color-white);
                    fill: none;
                }
            }

            .btn--ghost {
                background-color: var(--color-white);
                color: var(--color-black);
            }

            .btn--ghost:hover,
            .btn--ghost:focus {
                color: var(--color-primary);
                border-color: var(--color-primary);
            }

        }

        .footer--bar {
            color: var(--color-contrast-higher);

            .d8-u-color-white {
                color: var(--color-contrast-higher);
            }
        }
    }
}