.bo-2024 {
    --color-black: #0A1F44;
    --color-accent-1: #DC474B;
    --color-accent-2: #FAFF04;
    --color-accent-3: #FAE9DB;
    --color-accent-4: #064A5A;
    --color-accent-5: #DEE253;
    background-color: #EEEDE7;
    color: #1E314F;
    --font-secondary: ReworkText, sans-serif;

    h1, h2, h3, h4, h5 {
        // font-family: "modesto-condensed", serif; TODO!
        font-weight: 700;
        font-style: normal;
        // color: #1E314F;
    }

    .bg-accent-3 {
        background-color: var(--color-accent-3);
    }
    .bg-accent-1 {
        background-color: var(--color-accent-1);
    }
    .bg-accent-5 {
        background-color: var(--color-accent-5);
    }
    .bg-accent-5-light {
        background-color: lighten(#DEE253, 20%);
    }
    .bg-accent-5-lighter {
        background-color: lighten(#DEE253, 30%);
    }
    .bg-black-bo {
        background-color: var(--color-black);
    }
    .bg-transparent {
        background-color: transparent;
    }
    .text-accent-1 {
        color: var(--color-accent-1);
    }
    .text-accent-2 {
        color: var(--color-accent-2);
    }
    .text-accent-1-md {
        @include breakpoint(md) {
            color: var(--color-accent-1);
        }
    }
    .line-height-lg {
        line-height: 2;
    }
    .card-no-border-radius .d8-c-card {
        border-radius: 0;
    }
    .arrows-section {
        .d8-svg-arrowsLeft {
            position: absolute;
            left:0;
            top: 0;
            height: 100%;

            max-width: var(--space-xl);
            @include breakpoint(sm) {
                max-width: unset;
            }
        }

        .d8-svg-arrowsRight {
            position: absolute;
            right:0;
            top: 0;
            height: 100%;

            max-width: var(--space-xl);
            @include breakpoint(sm) {
                max-width: unset;
            }
        }
    }
    .long-link {
        width:100%;
        @include breakpoint(sm) {
            min-width: 305px;
            width:auto;
        }
    }
    .longer-link {
        width:100%;
        @include breakpoint(sm) {
            min-width: 600px;
            width:auto;
        }
    }
    .text-scaled-sm {
        font-size: var(--text-lg);
        @include breakpoint(md) {
            font-size: var(--text-xxxl);
        }
    }
    .text-scaled {
        font-size: var(--text-xxl);
        @include breakpoint(md) {
            font-size: var(--text-xxxxl);
        }
    }
    .text-scaled-lg {
        font-size: var(--text-xxl);
        @include breakpoint(md) {
            font-size: var(--text-xxxxxl);
        }
    }
    .text-scaled-xl {
        font-size: var(--text-xxl);
        @include breakpoint(md) {
            font-size: var(--text-xxxxxxl);
        }
    }
    .bo-packages {
        .d8-c-card {
            background-color: var(--color-accent-1);

            .card--image > div > div > div > div > h4 {
                color: #fff !important;
            }

            .d8-u-color-contrast-medium {
                color: var(--color-black) !important;
            }

            .d8-c-tooltip .icon path {
                fill: var(--color-black);
            }

            .list--item__right .icon path {
                fill: var(--color-accent-2);
            }

            h4, strong {
                @extend .d8-u-color-white;
            }
        }
        .card--basic > span > div {
            background-color: var(--color-accent-1);
        }
    }

    .locations-grid {
        & > div:nth-child(1){
            .location-icon {
                left: 0;
                transform: translateY(-50%);
                background-color: transparent;
            }
        }
        & > div:nth-child(2){
            .location-icon {
                right: 0;
                transform: translateY(-50%);
                background-color: transparent;
            }
        }
    }
    .bo-planning-next-steps {
        @include breakpoint(md) {
            background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/09/bo-planning-footer.png');
            background-size: cover;
            background-repeat: no-repeat;
        }
        .bo-planning-steps {
            @include breakpoint(md) {
                border: 2px solid var(--color-accent-1);
            }
        }
    }

    .bo-planning-location {
        overflow: visible;
        @include breakpoint(md) {
            border: 2px solid var(--color-accent-1);
        }

        @include breakpoint(md) {
            .location-icon {
                right: unset !important;
                bottom: 100%;
                left: 50% !important;
                transform: translateY(30%) translateX(-50%) !important;
                background-color: var(--color-accent-3) !important;
            }
        }
    }
    .limited-pricing-section {
        background-image: url("https://assets.theyachtweek.com/wp-content/uploads/2023/09/earlybird-landing-page-compressed.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @include breakpoint(md) {
            background-size: 100% auto;
            background-position: -50% 65%;
        }

    }
    .d8-u-curve-up {
        min-height: var(--space-xl);

        @include breakpoint(md) {
            min-height: var(--space-xxxl);
        }
        width: 100%;
        border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    }
    .d8-u-curve-down {
        min-height: var(--space-xl);
        max-width: 100vw;

        @include breakpoint(md) {
            min-height: var(--space-xxxl);
        }
        width: 100%;
        border-radius: 0 0 50% 50% / 0 0 100% 100%;
    }
    .corner-overflow {
        position: absolute;
        left: -4rem;
        top: -3rem;
        z-index: 5;
    }
    .corner-symbol {
        height: 50%;
        position: absolute;

        top: 0;
        right: 0;
        left: auto;

        @include breakpoint(md) {
            height: 62vh;
            left: calc(100% + var(--space-xl));
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .bo-2024-planning-tribe {
        z-index: 0;
        transform: scale(2.4);
        transform-origin: top left;
        @include breakpoint(md) {
            z-index: 5;
            transform:none;
        }
    }
    .triple-images {
        padding-bottom: var(--space-xxxl);
        @include breakpoint(sm) {
            padding-bottom: var(--space-xxxl);
        }
        @include breakpoint(md) {
            padding-bottom: var(--space-xxxxl);
        }
        position: relative;
        img:nth-child(1) {
           width: 68%;
           position: relative;
        }
        img:nth-child(2) {
            width: 38%;
            right: 0;
            bottom: 36%;
            position: absolute;
            @include breakpoint(sm) {
                width: 40%;
                bottom: 38%;
            }
         }
         img:nth-child(3) {
            width: 40%;
            bottom: 0;
            right: 9%;
            position: absolute;
         }

         .extra-1 {
            position: absolute;
            top: 3%;
            left: 57.7%;
            @include breakpoint(sm) {
                top: 3%;
            }
         }
         .extra-2 {
            position: absolute;
            bottom: 6%;
            left: 18%;
            @include breakpoint(sm) {
                bottom: 3%;
            }
            @include breakpoint(md) {
                bottom: 6%;
            }
         }
    }
    .d8-c-input {
        margin-bottom: 0;
        input {
            background-color: rgba(255,255,255,0.6) !important;
            border: 1px solid transparent !important;
            color: var(--color-black);
            &::placeholder {
                color: var(--color-black);
            }
        }
        .flag-dropdown  {
            border-right: 1px solid var(--color-black);
            &::after {
                border-color: var(--color-black);
            }
        }
        svg {
            path {
                fill: var(--color-black);
                stroke: var(--color-black);
            }
        }
    }

    .tyw_logo__wrapper {
        svg {
            max-width: 275px;
        }
    }
    .bo-2024-header {
        h1 {
            color: var(--color-accent-2);
            @include breakpoint(md) {
                font-size: 50px;
            }
            @include breakpoint(xl) {
                font-size: 80px;
            }
        }
        p {
            line-height: 1.5;
            @include breakpoint(md) {
                line-height: 2;
            }
        }
        @include breakpoint(md) {
            height: 100vh;
            // min-height: 100vh;
        }
    }
    .scrolling-banner {
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
        position: relative;
        display: flex;
        top: 0;
        z-index: 5;
        &:hover div {
          animation-play-state: paused;
        }

        div {
          display: flex;
          position: relative;
          animation: marquee 120s linear infinite;
          justify-content: space-around;
        }

        p {
            font-family: var(--font-secondary);
            font-weight: bold;
            display: block;
            height: 100px;
            min-width: 1300px;
            line-height: 100px;
            font-size: 80px;
            margin: 0 1vw;
            text-transform: uppercase;
        }
        &.long {
            p {
                min-width: 2410px;
            }
        }

        &.timeout {
            p {
                min-width: 1900px;
            }
        }

        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }

    .d8-svg-divider {
        margin-bottom: -1px;
        width:100%;
    }
    .lead__wrapper {
        position: relative;
        z-index: -1;
        .d8-svg-lead {
            margin-top: -3px;
        }
    }

    &.bo-2024-planning {
        .lead__wrapper {
            z-index: 0;
        }
    }

    .line-section__wrapper {
        overflow: hidden;
        .line__wrapper {
            z-index: -1;
            .d8-svg-line {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: auto;
                height: 100%;
                padding-bottom: var(--space-xl);
            }
        }
        .background-overlay {
            background: linear-gradient(to bottom, #89ADC7, #89ADC7, #DDE2E8);
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
        .background-symbol {
            position: absolute;
            display: none;
            opacity: 0.5;
            z-index: -1;
            top: 50%;
            transform: translateY(-50%);
            svg {
                width: 800px;
                height: 800px;
            }
            @include breakpoint(md) {
                display: block;
            }
        }
        .scrolling-banner__wrapper {
            p {
                color: var(--color-accent-1);
            }
        }
    }
    .large-heading-text__wrapper {
        h2 {
            @include breakpoint(sm) {
                font-size: 80px;
            }
        }
    }
    .bo-2024-intro {
        background-color: var(--color-accent-1);
        .icon--xxl {
            width: 64px;
            height: 64px;
            @include breakpoint(md) {
                width: 128px;
                height: 128px;
            }
        }
    }

    .d8-c-hero {
        height: 100%;
    }
    .spacer {
        height: 100px;
    }
    .party-lifetime {
        @include breakpoint(xs) {
            max-width: var(--max-width-xxxs);
        }
        @include breakpoint(sm) {
            max-width: var(--max-width-xs);
        }
    }
    .grid-cluster {
        --icon-xxl: 100px;
        h2 {
            @media only screen and (max-width: 385px) {
                font-size: 30px;
            }
        }
        &.cluster__1{
            @include grid;
            @include gridAdvanced(
                (4, 13, 1, 5),
                (1, 9, 2, 8),
                (7, 12, 5, 8)
                );
        }
        &.cluster__2{
            @include grid;
            @include gridAdvanced(
                (2, -1, 1, 4),
                (8, 13, 2, 6),
                (3, 12, 3, 6),
                (1, 6, 5, 7),
                );
                @media only screen and (max-width: 400px) {
                    @include gridAdvanced(
                (2, -1, 1, 4),
                (7, 12, 2, 6),
                (3, 11, 3, 6),
                (1, 6, 5, 7),
                );
                }
        }
        &.cluster__3{
            @include grid;
            @include gridAdvanced(
                (1, -1, 1, 3),
                (1, 12, 2, 5),
                (7, 12, 4, 7),
                (1, 8, 5, 9),
                (6, -1, 6, 9),
                (2, 8, 7, 9),
                );
                @media only screen and (max-width: 400px) {
                    @include gridAdvanced(
                    (1, -1, 1, 3),
                    (1, 12, 2, 5),
                    (7, 12, 4, 7),
                    (1, 8, 5, 9),
                    (5, 12, 6, 9),
                    (2, 8, 7, 9),
                    );
                }
        }

        &.cluster__5{
            @include grid;
            @include gridAdvanced(
                (1, -1, 1, 4),
                (4, 12, 2, 9),
                (1, 7, 6, 10),
                (5, 11, 8, 12)
                );
        }
    }
    .this-is-tyw__wrapper {
        min-height: 100vh;
        overflow: hidden;
        .cluster__7 {
            .tyw_logo__wrapper {
                svg {
                    max-width: 400px;
                }
            }
        }
        .bg-img_wrapper {
            img {
                @include breakpoint(md) {
                    object-position: 50% 30%;
                }
            }
        }
        .dates__wrapper {
            margin-bottom: 50px;
            @include breakpoint(sm) {
                margin-bottom: 100px;
            }
            @include breakpoint(lg) {
                margin-top: -200px;
                margin-bottom: 0;
            }
        }
        .paper1__wrapper {
            .d8-svg-paper1 {
                width: 100%;
            }
            .palm__wrapper {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -100%);
                svg {
                    width: 60px;
                    height: 60px;
                }
                @include breakpoint(md) {
                    transform: translate(-50%, -200%);
                }
            }
            @include breakpoint(md) {
                bottom: -250px;
                transform: rotate(5deg);
            }
        }
    }

    .bg-img_wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        img {
            position: absolute;
        width: 100%;
        height: 100%;
            object-fit: cover;

        }
    }
    .slider-section {
        background-color: var(--color-accent-3);
        margin-top: -2px;
        .slider-section__title {
            color: var(--color-accent-4);
            h2 {
                font-size: 35px;
                color: var(--color-accent-4);
            }
        }
        .bo-slider {
            --color-primary: var(--color-accent-1);
            .slider-item {
                position: relative;
                padding-top: 130px;
                margin: 20px 0;
                h3 {
                    width: 100%;
                    position: absolute;
                    top: 0%;
                    left: 50%;
                    transform: translateX(-50%);
                    color: var(--color-accent-1);
                }
                .img__wrapper {
                    // margin-top: -30px;
                    // margin-left: 10%;
                    // margin-right: 10%;
                }
                p {
                    line-height: 2;
                }
            }
            .custom-arrows {
                svg {
                    width: 20px;
                    height: 20px;
                    path {
                        fill: var(--color-accent-1);
                        stroke: var(--color-accent-1);
                    }
                }
            }
        }
        .d8-svg-paper2 {
            width: 100%;
        }
    }
    .bottom-section {
        .home-link-button {
            color: white;
        }
    }

    // GREENSOCK STUFF
    #line-ball {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        // visibility: hidden;
        // opacity: 0;
        // transform: scale(0);
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: var(--color-accent-1);
        // transition: all 2s var(--bounce);
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 0%;
            height: 0%;
            box-sizing: border-box;
            border-radius: 45px;
            background-color: var(--color-accent-1);
            opacity: 0;
            transition: opacity 2s var(--bounce), width 2s var(--bounce), height 2s var(--bounce) ;
        }
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 0%;
            height: 0%;
            box-sizing: border-box;
            border-radius: 45px;
            background-color: var(--color-accent-1);
            opacity: 0;
            transition: opacity 2s var(--bounce), width 2s var(--bounce), height 2s var(--bounce) ;
        }
        &.is-scrolling {
            &:before {
                width: 200%;
                height: 200%;
                opacity: 0.3;
                transform: translate(-50%, -50%);
                transition: opacity 2s var(--bounce), width 2s var(--bounce), height 2s var(--bounce) ;
            }
            &:after {
                width: 300%;
                height: 300%;
                opacity: 0.1;
                transform: translate(-50%, -50%);
                transition: opacity 2s var(--bounce), width 2s var(--bounce), height 2s var(--bounce) ;
            }
        }
    }
    .this-is-tyw__wrapper {
        .tyw_logo__wrapper {
            filter: blur(10px);
            opacity: 0;
        }
    }
    .lead-text__wrapper {
        clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
        opacity: 0;
        transform: translateY(10px);
    }
    .grid-cluster {
        .symbol__wrapper, .video__wrapper, .img__wrapper, h2, h3, p {
            clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
            opacity: 0;
            transform: translateY(50px);
        }
        h3 {
            transform: translateY(10px);
        }
    }
}
.bo-2024-planning {
    overflow-x: hidden;

    .scrolling-banner.long {
        p {
            min-width: 3520px;
        }
    }

    .tyw_logo__wrapper {
        svg {
            max-width: 380px;
        }
    }
}
.small-scrolling-banner {
    height: 30px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    position: absolute;
    top: 0;
    background-color: #ff4858;
    z-index: 5;

    .scrolling-banner {
        div {
            font-size: inherit;

            p {
                font-size: inherit;
                height: 30px;
                line-height: 1.8;
                min-width: 800px;
            }
        }
    }
}

.bo-slider {
    --color-primary: var(--color-accent-1);
    .slider-item {
        position: relative;
        margin: 20px 0;
        h3 {
            width: 100%;
            position: relative;
            color: var(--color-accent-1);
        }
        .img__wrapper {
            width: 100%;

            @include breakpoint(md) {
                margin-left: 10%;
                margin-right: 10%;
                width: 80%;
            }
        }
        p {
            line-height: 2;
        }
    }
    li.slick-active {
        background-color: var(--color-black);
    }
    .custom-arrows {
        display: none;
        @include breakpoint(md) {
            display: block;
        }
        svg {
            width: 20px;
            height: 20px;
            path {
                fill: var(--color-black);
                stroke: var(--color-black);
            }
        }
    }
}