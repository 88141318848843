.ywcollective {
    --dui-color-primary-950: #fff;
    --dui-color-primary-900: #000;
    --dui-color-primary-800: white;
    --dui-color-primary-700: #FED5D1;
    --dui-color-primary-600: #FED5D1;
    --dui-color-primary-500: #FED5D1;
    --dui-color-primary-400: #FED5D1;
    --dui-color-primary-300: #FED5D1;
    --dui-color-primary-200: #FED5D1;
    --dui-color-primary-100: #111;
    --dui-color-primary-50: #FED5D1;
    --dui-color-secondary-950: #fff;
    --dui-color-secondary-900: #000;
    --dui-color-secondary-800: white;
    --dui-color-secondary-700: #FED5D1;
    --dui-color-secondary-600: #FED5D1;
    --dui-color-secondary-500: #FED5D1;
    --dui-color-secondary-400: #FED5D1;
    --dui-color-secondary-300: #FED5D1;
    --dui-color-secondary-200: #FED5D1;
    --dui-color-secondary-100: #111;
    --dui-color-secondary-50: #FED5D1;

    --color-primary: #FED5D1;
    --color-bg: #000;

    --letter-spacing: 1px;
    color: #fff;

    h1,
    h2,
    h3,
    h4,
    h5 {
        letter-spacing: var(--letter-spacing);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
        color: #fff;
    }

    .min-height-60vh {
        min-height: 60vh;
    }

    .height-450px {
        height: 450px;
    }

    .gap-md {
        gap: var(--space-md);
    }

    .width-20p {
        width: 20%;
    }

    .btn--primary,
    input[type="submit"] {
        color: var(--color-bg);
        border-radius: var(--radius-md);
        font-weight: 600;
        text-transform: uppercase;

        &:hover,
        &:focus {
            background-color: var(--color-bg);
            color: var(--color-primary);
            border-color: var(--color-primary);
        }
    }

    .collective-faq>div {
        margin-top: 0;
        margin-bottom: 0;
    }

    .heading-font {
        font-family: var(--font-secondary);
        text-transform: uppercase;
        letter-spacing: var(--letter-spacing);
        font-weight: 600;
    }

    .d8-c-collapse .d8-c-collapse-original {
        border: 1px solid var(--color-primary);
        border-radius: var(--radius-md);
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin-bottom: var(--space-sm);

        &:hover {
            header {
                background-color: transparent;
            }
        }

        header {
            margin-top: 0;
            font-family: var(--font-secondary);
            text-transform: uppercase;
            letter-spacing: var(--letter-spacing);
            border-radius: 0;

            &:hover,
            &:focus {
                background-color: transparent;
            }

            &.is-open {
                border-bottom: 1px dashed var(--color-primary);
            }

            &:after {
                color: var(--color-primary);
            }
        }

    }

    input,
    select {
        background: transparent;
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
        text-transform: uppercase;
        font-weight: 600;

        &::placeholder {
            color: var(--color-primary);
            text-transform: uppercase;
            font-weight: 600;
        }
    }

    .scroll-snapper {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
        height: 350px;
        width: 100%;
        /* Full viewport width */
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        //hide scroll bar
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        //allow drag
        touch-action: pan-x;

        margin-left: var(--component-padding);
        max-width: calc(100% - var(--component-padding));

        &-header {
            display: flex;
            flex-direction: row;
            margin-left: var(--component-padding);
            align-items: center;
            justify-content: flex-start;
            width: calc(100% - var(--component-padding));

            .bg-line {
                width: 100%;
                height: 1px;
                background-color: var(--color-primary);
            }
        }
    }

    .d8-u-border-collective {
        border: 1px solid var(--color-primary);
    }

    .margin-bottom-0 {
        margin-bottom: 0;
    }

    .card-item-half {
        margin-right: var(--component-padding);
        width: calc(45% - (var(--component-padding) / 2));
        /* Half the viewport width */
        scroll-snap-align: start;
        flex: 0 0 calc(55% - (var(--component-padding) / 2));
        /* Ensure each card takes half of the viewport width */
        display: flex;
    }

    .collective-card {
        &:hover {
            .collective-card-hover {
                opacity: 1;
            }

            .collective-card-heading {
                opacity: 0;
            }
        }

        .collective-card-heading {
            opacity: 1;
            transition: opacity 0.5s ease;
        }

        .collective-card-hover {
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: flex;
            justify-content: flex-start;
            padding: var(--space-xs);
            opacity: 0;
            transition: opacity 0.5s ease;
        }
    }

    .collective-card {
        min-height: 120px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        border-radius: var(--radius-md);
        padding: var(--space-xs);
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: cover;
            object-position: middle 46%;
            -webkit-filter: grayscale(100%) brightness(0.8);
            /* Safari 6.0 - 9.0 */
            filter: grayscale(100%) brightness(0.8);
        }
    }

}

.d8-c-collective-divider {
    width: 100%;
    border-top: 1px solid var(--color-primary);
}