@font-face {
    font-family: 'Golddrew';
    src: url(https://cdn.theyachtweek.com/assets/fonts/golddrew/Golddrew.eot);
    src: url(https://cdn.theyachtweek.com/assets/fonts/golddrew/Golddrew.eot?#iefix) format('embedded-opentype'),
         url(https://cdn.theyachtweek.com/assets/fonts/golddrew/Golddrew.otf) format('otf'),
         url(https://cdn.theyachtweek.com/assets/fonts/golddrew/Golddrew.svg) format('svg'),
         url(https://cdn.theyachtweek.com/assets/fonts/golddrew/Golddrew.woff) format('woff'),
         url(https://cdn.theyachtweek.com/assets/fonts/golddrew/Golddrew.woff2) format('woff2');
  }

.sol-button {
    background-color: #d76582;
    color: #fbf4cd;
    font-size: 2em;
    border: none;
    line-height: 1;
    padding: 0.3em 0.5em 0.15em 0.5em;

    &:hover {
        background-color: #F9D55D;
        border: none;
    }
}

.speech-bubble {
    background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2024/01/speechbubble.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-bottom: calc(var(--space-xl) + 20px);
    margin-bottom: var(--space-lg);

    p {
        color: var(--color-white);
    }
}

.summer-of-love {
    background-color: #fbf4cd;
    color: #d76582;

    .bullet-list {
        padding-left: 18px;
        list-style-type: disc;
    }
    

    .golddrew {
        font-family: "Golddrew", sans-serif;
        font-weight: 700;
        font-style: normal;
    }

    .text-transform-none {
        text-transform: none;
        font-family: var(--font-secondary);
    }
    
    .intro-wrapper {
        .btn--white {
            display: none;
        }
    }

    h1, h2, h3, h4, h5 {
        font-weight: 700;
        font-style: normal;
        color: #d76582;
    }

    .tall-banner {
        min-height: 80vh;
    }

    .sol-header {
        min-height: auto;

        .hero-overlay {
            z-index: 4;
            position:relative;
        }
    }

    .justify-even {
        justify-content: space-evenly;
    }

    h2 {
        span {
            color: #f1c300;
        }
    }

    .flower-row {
        .flower-image {
            max-width: 75px;
        }

        h3 {
            min-width: -webkit-fill-available;
            min-width: -moz-fit-content;
            min-width: fit-content;
        }
    }

    .discount-box {
        background-color: #d76582;
        color: #fbf4cd;
        border-radius: var(--radius-md);
        padding: var(--space-xs);
    }

    .promo-banner {
        background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2024/01/sol-banner-bg-no-color.png');
        background-size: 100% auto;
        background-position: left bottom;
        background-repeat: no-repeat;
        background-color: #d76582; 
        box-shadow: var(--shadow-lg);

        h3, h4 {
            color: var(--color-white);
        }
    }

    .sol-logo {
        margin-bottom: -28%;

        @media (min-width: 48em) {
            max-width: 85%;
            margin-bottom: -24%;
        }
    }

    .wave-left {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
    }

    .wave-right {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 2
    }

    #reviews {
        max-width: calc(100% - (var(--component-padding) * 2));
        margin: auto;
    }

    .highlights-wrapper {
        h2.d8-u-ls-sm {
            display: none;
        }

        .tabs--options {
            display: none;
        }

        .card-slider-controls {
            background: none;
        }
    }

    .full-on-mobile {
        margin-left: -20px;
        width: calc(100% + 40px);
    }

    .pull-up-footer {
        margin-bottom: -5vh;
        @include breakpoint(lg) {
            margin-bottom: -20vh;
        }
    }


    .flower-section {
        position: relative;
        .flower-right, .flower-left {
            //display: none;
            z-index: 9
        }

        .flower-right-block, .flower-left-block{
            z-index: 20;
            display: block;
            position: absolute;
            top: 0;
            height: 100%;
            width: 20px;
            background-size: 100% auto;
            background-repeat: round;
            
            @include breakpoint(sm) {
                display: none;
            }
        }

        .flower-left-block {
            background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2024/01/border-pattern.png');
            left: 0;
        }

        .flower-right-block {
            background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2024/01/border-pattern-right.png');
            right: 0;
        }


        .flower-left, .flower-right {
            width: 15px;
                background-size: 100% auto;
                background-repeat: round;
                display: block;
                position: absolute;
                top: 0;
                height: 100%;
        }
        .flower-left {
            left: 0;
                background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2024/01/border-pattern.png');
        }

        .flower-right {
            right: 0;
                background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2024/01/border-pattern-right.png');
        }

        @include breakpoint(md) {
            .flower-left {
                left: 10px;
                width: 40px;
            }

            .flower-right {
                right: 10px;
                width: 40px;
            }
        }
        
        @media (min-width: 96em) {
            .flower-left {
                left: 20px;
                width: 7vw;
            }

            .flower-right {
                right: 20px;
                width: 7vw;
            }
        }
    }

    .sol-destinations {
        h3 {
            color: var(--color-white);
        }
        .btn--primary {
            @extend .golddrew;
            @extend .sol-button;
            font-size: 16px;
            margin-top: 35px;
        }

        .card-slider-controls {
            background: #fbf4cd;
        }
    }

    .sol-adventure {
        img {
            max-height: 250px;
        }
        h3 span {
            text-transform: none;
        }
    }

    .paper-tear {
        transform: translateY(-50%);
        z-index:15;
        svg {
            width: 100%;
            min-width: 1200px;
        }

        @media (min-width: 48em) {
            transform: translateY(-53%);

            svg {
                min-width: 160vw;
            }
        }
    }

    .paper-tear-top {
        transform: translateY(-58%);
        z-index:15;
        svg {
            width: 100%;
            min-width: 1200px;
        }

        @media (min-width: 48em) {
            transform: translateY(-57%);

            svg {
                min-width: 160vw;
            }
        }
    }
    .sea-myth-intro {
        background-image: url("https://assets.theyachtweek.com/wp-content/uploads/2023/01/map1.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .repeat-map {
        background-image: url("https://assets.theyachtweek.com/wp-content/uploads/2023/01/map1.png");
        background-size: 100% auto;
        background-repeat: repeat-y;
    }

    .d8-c-carousel {
        .custom-arrows {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            padding: 0;
            top: 90%;

            &.next {
                right: 45%;
            }

            &.prev {
                left: 45%;
            }

            @media (min-width: 48em) {
                top: 50%;

                &.next {
                    right: 24px;
                }

                &.prev {
                    left: 24px;
                }
            }

            svg {
                g {
                    path {
                        fill: transparent;
                    }
                }
            }

            &.prev {
                background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/arrowleft.png');
            }

            &.next {
                background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/arrowright.png');  
            }
        }
    }

    .sea-myth-slider-item {
        width: 100vw;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        color: #FFE000;

        .event-svg {
            max-height: 150px;
            max-width: 80%;

            @media (min-width: 48em) {
                max-height: 300px;
            }
        }

        .sea-myth-background {
            background-color: #231F20;
            opacity: .54;
            z-index:0;
        }


        .bordered-content {
            max-width:100%;

            @media (min-width: 48em){
                max-width: 90%;
                margin-left: auto;
                margin-right:auto;
            }
        }

        &.moby {
            background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/seamythslide1.png');
        }
        &.fortress {
            background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/seamythslide2.png');
        }
        &.race {
            background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/seamythslide3.png');
        }
        &.eye {
            background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/seamythslide4.jpg');
        }
    }

    .sea-myth-adventure {
        svg {
            max-width: 70%;
        }
    }

    .sea-myth-destinations {
        .d8-c-card .card--image {
            &::before {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, .3);
                content: " ";
                z-index:1;
            }

            .image--destination__bottom {
                top: 0;
                position: absolute;
                height: 100%;
                width: 100%;
                padding: 0;
                display: flex;
                justify-content: center; 
                align-items: center;

                background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2023/01/locationborder.png');
                background-size: 95% 96%;
                background-repeat: no-repeat;
                background-position: center;

                .text-component {
                    h3, p {
                        text-align: center;
                        color: #FFE000;
                    }

                    h3 {
                        @extend .text-xxl;

                        span {
                            font-size: 18px !important;
                        }
                    }

                    p {
                        width: 100%;
                        border-right: 0;
                        @extend .text-sm;

                        span {
                            display: none;
                        }
                    }

                    @media (min-width: 48em) {
                        .btn {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .squid-footer {
        width: 100%;
        margin-bottom: -5px;
        position: relative;
        overflow:hidden;

        .footer-shape {
            max-width:unset;
            width:160vw;
            margin-left: -28vw;

            @media (min-width: 48em) {
                display: block;
	            max-width: 100%;
                width:100%;
	            height: auto;
                margin-left:0;
            }
        }
    }

    .sol-footer {
        background-color: #d76582;
        padding-top: var(--space-xl);

        h2 {
            color: var(--color-white);
        }

        svg path {
            fill: #d76582;
            stroke: var(--color-white);
        }
    }

    .longer-link {
        width:100%;
        @include breakpoint(sm) {
            min-width: 600px;
            width:auto;
        }
    }
    
    .d8-c-hero {
        height: 100%;
    }

    .hero-content {
        p.text-lg {
            font-size: 16px;
        }
    }

    @media (min-width: 48em) {
        .hero-content {
            width: 40%;
            margin-top: var(--space-xs);

            & > div {
                margin-top: var(--space-xs);
                margin-bottom: var(--space-xs);

                & > p.text-lg {
                    font-size: 18px;
                }
            }

            

            .text-component {
                & > div {
                    flex-wrap: wrap;
                    justify-content: center;

                    & > div {
                        max-width: 40%;
                        flex-basis: auto;
                    }

                    & > div:last-child {
                        max-width: 55%;
                    }
                }
            }
        }
    }


    .page-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 100;
        object-fit: cover;
        background-image: url(https://cdn.theyachtweek.com/assets/textures/texturesmaller.png);
        background-size: 100% auto;
        background-repeat: repeat-y;
        mix-blend-mode: difference;
        pointer-events: none;
    }
    
    .whitespace-normal {
        white-space: normal;
    }

    .flower-image {
        max-width: 50%;

        @media (min-width: 48em) {
            max-width: 90%;
        }
    }

    .sol-packages {
        .d8-c-card {
            background-color: #87A96B;
            background-color: #F9D55D;

            .card--image > div > div > div > div > h4 {
                color: #d76582 !important;
            }

            .d8-u-color-contrast-medium {
                color: var(--color-black) !important;
            }

            .d8-c-tooltip .icon path {
                fill: var(--color-black);
            }

            .list--item__right .icon path {
                fill: var(--color-accent-2);
            }

            h4, strong {
                color: #d76582 !important;
            }
        }
        .card--basic > span > div {
            background-color: #F9D55D;
        }
    }

    .golden-bg {
        background: #F9D55D;
        z-index: 10;
        position:relative;
        box-shadow: var(--shadow-lg);
        width: 100%;
    }

    div#__next {
        position: relative;
    }
}
