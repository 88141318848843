@import "../../node_modules/day8-react/build/styles/design-system/custom-style/_grid-mixins.scss";

.destination-bvi {
    --color-bvi: #009696;
    --color-primary: var(--color-bvi);
    --color-bg: #F1F2EF;

    .d8-c-sticky-banner {
        background-color: hsl(208deg, 100%, 50%);
    }

    .d8-c-hero {
        .btn.btn--primary {
            background-color: var(--color-primary);
            color: var(--color-white);
        }
    }

    .intro-wrapper {
        position: relative;

        @media only screen and (min-width: 1100px) {
            background-image: url(https://assets.theyachtweek.com/wp-content/uploads/2020/07/hero-bg.png);
            background-position: center;
            background-size: cover;
            margin-top: 0;
            padding-top: var(--space-xxl);
            margin-bottom: 0;
            padding-bottom: var(--space-xxl);
        }

        .tyw-c-intro {
            .intro-top {
                svg {

                    path,
                    g {
                        stroke: var(--color-bvi);
                    }
                }

                p {
                    color: var(--color-bvi);
                }

                .content {
                    p {
                        color: var(--color-black);
                    }
                }
            }

            .top-bottom {
                .d8-u-color-contrast-higher {
                    color: var(--color-bvi);
                }
            }
        }
    }

    .tyw-c-key-facts {
        // background-image: url(https://assets.theyachtweek.com/wp-content/uploads/2020/07/key-factsBVI.jpg);
        padding: calc(4 * var(--component-padding)) 0;
        position: relative;
    }

    .highlights-wrapper {
        // background: url(https://cdn.theyachtweek.com/assets/destinations/caribbean/leaf2-01.svg) center no-repeat;
    }

    .d8-c-destination-map {
        position: relative;

        &::before {
            display: none;
            content: "";
            position: absolute;
            top: 50%;
            left: -10%;
            transform: translateY(-50%);
            height: 505px;
            width: 730px;
            // background-image: url(https://cdn.theyachtweek.com/assets/destinations/caribbean/deco3.svg);
            background-position: center;
            background-repeat: no-repeat;

            @media only screen and (min-width: 1024px) {
                display: block;
            }
        }

        .map-card {
            .d8-svg-location-bold {

                path,
                g {
                    fill: var(--color-primary);
                }
            }
        }
    }

    // .deco-svg{
    //     position: absolute;
    //     top: 50%;
    //     left: -10%;
    //     transform: translateY(-50%);
    // }
    #tyw-c-weekly-tabs-wrapper {
        position: relative;

        &::after {
            display: none;
            content: "";
            position: absolute;
            bottom: -100px;
            left: 20%;
            height: 155px;
            width: 218px;
            background-image: url(https://cdn.theyachtweek.com/assets/destinations/caribbean/deco8.svg);
            background-position: center;
            background-repeat: no-repeat;
        }

        @media only screen and (min-width: 1024px) {
            background: url(https://cdn.theyachtweek.com/assets/destinations/caribbean/deco4.svg) top left no-repeat;
            margin-top: 0;
            padding-top: var(--space-xxl);

            &::after {
                display: block;
            }
        }

        .container {
            background: none;
            background-image: url(https://cdn.theyachtweek.com/assets/destinations/caribbean/deco5.svg), url(https://cdn.theyachtweek.com/assets/destinations/caribbean/deco6.svg);
            background-position: center;
            background-repeat: no-repeat;

            .weekly-tabs-wrapper {
                background-color: var(--color-white);
                border-radius: var(--radius);
                padding: var(--component-padding);
                position: relative;
                z-index: var(--zindex-header);

                .d8-c-card {
                    background: var(--color-contrast-lower);
                    box-shadow: none;
                }
            }
        }
    }

    .tyw-c-step-by-step {
        position: relative;

        @media only screen and (min-width: 1024px) {
            background: url(https://cdn.theyachtweek.com/assets/destinations/caribbean/deco7.svg) top right no-repeat;
        }

        &::before {
            display: none;
            content: '';
            position: absolute;
            bottom: 15%;
            right: 0;
            background: url(https://cdn.theyachtweek.com/assets/destinations/caribbean/deco9.svg) top left no-repeat;
            width: 240px;
            height: 185px;

            @media only screen and (min-width: 1280px) {
                display: block;
            }
        }

        .step-3 {
            position: relative;

            &::before {
                display: none;
                content: '';
                position: absolute;
                top: 50%;
                right: 100%;
                transform: translateY(-50%);
                background: url(https://cdn.theyachtweek.com/assets/destinations/caribbean/deco10.svg) top left no-repeat;
                width: 213px;
                height: 280px;

                @media only screen and (min-width: 1280px) {
                    display: block;
                }
            }
        }
    }

    .tyw-c-destination-reviews {
        // background: url(https://cdn.theyachtweek.com/assets/destinations/caribbean/deco11.svg) top right no-repeat, url(https://cdn.theyachtweek.com/assets/destinations/caribbean/deco12.svg) bottom left no-repeat;
        background-color: #E1EBE5;
    }
}

.tyw-c-bvi-decoration {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media only screen and (min-width: 1100px) {
        display: block;
    }

    .hero2-svg {
        display: none;
        position: absolute;
        top: 0;
        left: 0;

        @media only screen and (min-width: 1650px) {
            display: block;
        }
    }

    .hero3-svg {
        position: absolute;
        bottom: -20px;
        left: 10%;
    }

    .decoration__wrapper {
        position: absolute;
        top: 0;
        right: 0;

        .hero1-svg {
            position: absolute;
            top: 0;
            right: 0;
            max-width: 462px;
        }

        .grid__wrapper {
            @include grid;
            @include gridAdvanced((1, 8, 2, 8), (7, -1, 3, 7), (5, -1, 4, 7));

            .destination-1 {
                position: relative;
                max-width: 325px;
                height: auto;
            }

            .destination-2 {
                position: relative;
                max-width: 192px;
                height: auto;
            }

            .leaf-svg {
                position: relative;
                z-index: -1;
                max-width: 172px;
            }

        }

        @media only screen and (min-width: 1650px) {
            .hero1-svg {
                max-width: 660px;
            }

            .grid__wrapper {
                @include grid;
                @include gridAdvanced((1, 8, 2, 8), (7, -1, 3, 7), (5, -1, 5, 7));

                .destination-1 {
                    max-width: 465px;
                }

                .destination-2 {
                    max-width: 275px;
                }

                .leaf-svg {
                    max-width: 246px;
                }
            }
        }
    }

}