@import "../../node_modules/day8-react/build/styles/design-system/custom-style/_grid-mixins.scss";
.destination-hideout {
    // --color-contrast-low: hsl(38, 34%, 88%);
    --color-black: #000000;
    --color-white: #ffffff;
    --color-hideout-mint: #73FCB2;
    --color-hideout-violet: #ab70f0;
    --color-hideout-blue: #5085fa;
    --color-hideout-turquoise: #67D2DF;
    --color-bg: var(--color-hideout-mint);
    --color-primary: var(--color-black);
    --color-secondary: var(--color-hideout-blue);
    .d8-c-navbar {
        background: var(--color-hideout-mint);
    }
    .d8-c-sticky-navbar {
        background: var(--color-hideout-blue);
        .d8-u-color-contrast-high {
          color: var(--color-black);
        }
    }
    .btn {
        border-radius: 0;
        &.btn--white {
            color: var(--color-black);
            background-color: var(--color-hideout-blue);
            svg {
                g, path {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
        }
        &.btn--ghost {
            color: var(--color-black);
            border-color: var(--color-black);
        }
    }

    .d8-c-hero{
        .btn.btn--primary {
            background-color: var(--color-primary);
            color: var(--color-white);
        }
    }
    .tyw-c-intro {
        .intro-top{
            svg {
                path,
                g {
                    stroke: var(--color-hideout-blue);
                }
            }
            p{
                color: var(--color-hideout-blue);
            }
            .content{
                p{
                    color: var(--color-black);
                }
            }
        }
        .top-bottom{
            .d8-u-color-contrast-higher{
                color: var(--color-hideout-blue);
            }
        }
        a {
            color: var(--color-hideout-blue);
        }
    }
    .d8-c-tabs {
        .btn--underline {
            color: var(--color-hideout-blue);
            &.active {
                color: var(--color-black);
            }
        }
    }
    .d8-c-carousel {
        .btn--circle-icon-ghost {
            background-color: var(--color-black);
            border-color: var(--color-black);
            color: var(--color-white);
            svg {
                path, g{
                    fill: var(--color-white);
                    stroke: var(--color-white);
                }
            }

        }

    }
    .d8-c-destination-map {
        .map-card {
            .d8-svg-location-bold {
                g, path {
                    fill: var(--color-hideout-blue);
                    stroke: var(--color-hideout-blue);
                }
            }
            .d8-u-color-primary {
                color: var(--color-hideout-blue);
            }
            .btn--circle-icon-primary {
                color: var(--color-white);
            }
        }
    }

    #tyw-c-weekly-tabs-wrapper{
        .d8-u-contrast-lower-bg {
            background-color: transparent;
        }
    }

    .d8-c-footer {
        background-color: var(--color-hideout-blue);
        .d8-u-color-contrast-high {
            color: var(--color-black);
        }
    }

    .pill--primary-outline {
      background-color: transparent;
      color: var(--color-black);
      border: 1px solid var(--color-black);
      svg {
          path,
          g {
              stroke: var(--color-black);
          }
      }
    }
    .pill--purple-outline {
      background-color: transparent;
      color: var(--color-hideout-blue);
      border: 1px solid var(--color-hideout-blue);
      svg {
          path,
          g {
              stroke: var(--color-hideout-blue);
          }
      }
    }
    .d8-u-contrast-low-bg {
        background-color: var(--color-hideout-blue);
    }

    .d8-c-card-slider .card-slider-controls.right {
        background: linear-gradient(-90deg, #73FCB2, #73FCB200);
    }

    .d8-c-card-slider .card-slider-controls.left {
        background: linear-gradient(-90deg,  #73FCB200 , #73FCB2);
    }
}
