.destination-vibras {
    --color-primary: #449962;
    --color-secondary: #85CD8D;
    --color-bg: #e3ffe7;

    .d8-c-card-slider {
        .card-slider-controls {
            &.right {
                background: none;
            }
            &.left {
                background: none;
            }
        }
    }
}
