.destination-greece-bootcamp {
    --color-bg: var(--color-diva-blue);
    --color-diva-blue: rgb(0, 123, 178);

    #__next {

        --color-tropical-breeze: rgb(136, 205, 231);
        --color-primary: var(--color-tropical-breeze);
        --color-primary-darker: var(--color-diva-blue);
        --color-primary-dark: var(--color-diva-blue);
        --color-contrast-low: var(--color-diva-blue);
        --color-contrast-lower: var(--color-diva-blue);
        --color-contrast-high: rgba(136, 205, 231, .6);

        .tabs--content p {
            color: var(--color-white);
        }

        .d8-c-card-slider {
            .card-slider-controls {
                &.right {
                    background: none;
                }

                &.left {
                    background: none;
                }
            }
        }

        h2 {
            color: var(--color-white);
        }

        .d8-u-text-bold.text-md.margin-top-sm.d8-u-color-black,
        p.margin-bottom-lg {
            color: var(--color-white);
        }

        .intro-wrapper {
            .text-component {
                color: var(--color-white);

                h2,
                h3,
                h4,
                h5,
                h6 {
                    color: var(--color-white);
                }
            }
        }
    }
}