/* globals.css */
:root,
[data-theme="default"] {
  /* Font Variables */
  --dui-font-heading: 'Anton', sans-serif;
  --dui-font-subheading: 'Antonio', sans-serif;
  --dui-font-body: 'Inter', sans-serif;

  /* Default color variables */
  --dui-color-white: #fff;
  --dui-color-black: #000;

  /* Primary Color Variables */
  --dui-color-primary-50: #f2f7fd;
  --dui-color-primary-100: #e3eefb;
  --dui-color-primary-200: #c1ddfc;
  --dui-color-primary-300: #8ac0ef;
  --dui-color-primary-400: #4ca0e4;
  --dui-color-primary-500: #2584d2;
  --dui-color-primary-600: #1667b3;
  --dui-color-primary-700: #135291;
  --dui-color-primary-800: #144778;
  --dui-color-primary-900: #163c64;
  --dui-color-primary-950: #122f51;

  /* Secondary Color Variables */
  --dui-color-secondary-50: #b8f5ec;
  --dui-color-secondary-100: #a3f2e7;
  --dui-color-secondary-200: #7aeddc;
  --dui-color-secondary-300: #52e7d1;
  --dui-color-secondary-400: #29e2c6;
  --dui-color-secondary-500: #00dcbb;
  --dui-color-secondary-600: #00b99d;
  --dui-color-secondary-700: #00967f;
  --dui-color-secondary-800: #007261;
  --dui-color-secondary-900: #004f43;

  /* Primary-Dark Color Variables */
  --dui-color-primary-dark-50: #bdc5ce;
  --dui-color-primary-dark-100: #bdc5ce;
  --dui-color-primary-dark-200: #aab4c0;
  --dui-color-primary-dark-300: #8493a5;
  --dui-color-primary-dark-400: #5e7289;
  --dui-color-primary-dark-500: #38506d;
  --dui-color-primary-dark-600: #0f2744;
  --dui-color-primary-dark-700: #0c2037;
  --dui-color-primary-dark-800: #09182a;
  --dui-color-primary-dark-900: #06111d;

  /* Additional Color Variables (Sunny Yellow, Peach, Dusty Rose, Bright Blue, Dark) */
  /* sunny-yellow Color Variables */
  --dui-color-sunny-yellow-50: #feffd6;
  --dui-color-sunny-yellow-100: #feffca;
  --dui-color-sunny-yellow-200: #fdffb3;
  --dui-color-sunny-yellow-300: #fdff9b;
  --dui-color-sunny-yellow-400: #fcff84;
  --dui-color-sunny-yellow-500: #fcff6c;
  --dui-color-sunny-yellow-600: #d4d65b;
  --dui-color-sunny-yellow-700: #abad49;
  --dui-color-sunny-yellow-800: #838538;
  --dui-color-sunny-yellow-900: #5b5c27;

  /* peach Color Variables */
  --dui-color-peach-50: #fae9db;
  --dui-color-peach-100: #fef9f5;
  --dui-color-peach-200: #fdf7f2;
  --dui-color-peach-300: #fcf4ec;
  --dui-color-peach-400: #fcf0e7;
  --dui-color-peach-500: #fbede1;
  --dui-color-peach-600: #d2c4b8;
  --dui-color-peach-700: #aa9e95;
  --dui-color-peach-800: #827972;
  --dui-color-peach-900: #5a544f;

  /* dusty-rose Color Variables */
  --dui-color-dusty-rose-50: #f1d7e9;
  --dui-color-dusty-rose-100: #fbf4f9;
  --dui-color-dusty-rose-200: #faf1f7;
  --dui-color-dusty-rose-300: #f8eaf4;
  --dui-color-dusty-rose-400: #f5e4f0;
  --dui-color-dusty-rose-500: #f3dded;
  --dui-color-dusty-rose-600: #cab5c4;
  --dui-color-dusty-rose-700: #a4929e;
  --dui-color-dusty-rose-800: #7d7079;
  --dui-color-dusty-rose-900: #574d54;

  /* bright-blue Color Variables */
  --dui-color-bright-blue-50: #f1d7e9;
  --dui-color-bright-blue-100: #fbf4f9;
  --dui-color-bright-blue-200: #faf1f7;
  --dui-color-bright-blue-300: #f8eaf4;
  --dui-color-bright-blue-400: #f5e4f0;
  --dui-color-bright-blue-500: #f3dded;
  --dui-color-bright-blue-600: #cab5c4;
  --dui-color-bright-blue-700: #a4929e;
  --dui-color-bright-blue-800: #7d7079;
  --dui-color-bright-blue-900: #574d54;

  /* dark Color Variables */
  --dui-color-dark-100: #111928;
  --dui-color-dark-200: #1f2a37;
  --dui-color-dark-300: #374151;
  --dui-color-dark-400: #4b5563;
  --dui-color-dark-500: #6b7280;
  --dui-color-dark-600: #9ca3af;
  --dui-color-dark-700: #d1d5db;
  --dui-color-dark-800: #e5e7eb;
  /* (Add similar CSS variable declarations for other color palettes as shown above) */
}