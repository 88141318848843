.yacht-vs-cabin {
    .tabs-grid {
        z-index: 0;
        padding: var(--component-padding);
        .d8-l-half-grid {
            grid-auto-rows: unset;
        }
        .smooth-scroll-button {
            color: var(--color-primary);
            svg {
                g,
                path {
                    fill: var(--color-primary);
                    stroke: var(--color-primary);
                }
            }
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--color-contrast-low);
            z-index: -1;
            border-radius: var(--radius-md);
        }
        @include breakpoint(sm) {
            padding: var(--space-xxxs) 0;
            &::after {
                position: absolute;
                left: -20%;
                width: 100%;
                height: 100%;
            }
            .d8-l-half-grid {
                grid-auto-rows: 1fr;
            }
        }
    }
}
