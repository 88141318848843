.what-is-nw {
    background-color: #133364;
}

.nw-itinerary, .netweek-featured-text{
    .full-svg {
        margin-top: -1px;
        &.bottom {
            margin-top: 0;
            margin-bottom: -1px;
        }
    }
}

.netweek-featured-text {
    h2 {
        animation: marquee 180s linear infinite;
    }

    .text-xxxl {
        font-size: 130px;
        width: max-content;
        color: #ff4858;

        @media (max-width: 768px) {
            font-size: 60px;
        }
    }

    svg {
        z-index: 1;
    }
}

.netweek-how-it-works {
    .netweek-hiw-arrow {
        display: none;
        @media (min-width: 64rem) {
            display: inline-block;
            position: absolute;
            right: 0;
            transform: translateX(100%);
            &.first {
                top: 111px;
            }
            &.second {
                top: 196px;
            }
        }
    }

    .cog {
        width: 50px;
    }

    @media (min-width: 64rem) {
        .cog-1 {
            display: none;
        }

        .cog {
            width: 100px;
        }
    }

    .netweek-image-with-svg {
        width: 100%;

        .d8-c-card {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
            transform: rotate(5deg);
            border-radius: 30px;

            .d8-u-overlay {
                border-radius: 30px;
            }
        }

        svg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        @media (min-width: 64rem) {
            position: absolute;
            top: 0;
        }
    }
}

.padding-top-custom {
    @media (min-width: 64rem) {
        padding-top: calc(10.5 * var(--space-unit));
    }
}

.nw-itinerary {
    background: rgb(5,81,121);
    background: linear-gradient(207deg, rgba(5,81,121,1) 0%, rgba(19,51,100,1) 100%);

    .google-map > div > div > div {
        border-radius: 0px;
    }

    .d8-c-destination-map {

        h2 {
            display: none;
        }

        .google-map {
            margin-left: -20px;
            margin-bottom: -20px;
            width: 100vw;

            @media (min-width: 64rem) {
                width: auto;
                margin-bottom: auto;
                margin-left: auto;

                & > div > div > div {
                    border-radius: 20px;
                }
            }
        }

        @media (min-width: 64rem) {
            width: 93%;
            margin-left: auto;
            margin-right: 0;
            padding-bottom: var(--space-xl);
        }
    }

    .nw-itinerary-card-contain {
        @media (min-width: 64rem) {
            position: absolute;
            width: 30%;
            bottom: 0;
            left: 0;
    
        }
    }

    .itinerary-markers {
        width: 43px;

        @media (min-width: 64rem) {
            width: 104px; 
        }
    }
}

.netweek-sign-up {
    background-color: #00D6C6;
}

.netweek-button {
    background-color: #FF4858;
    border-color: #FF4858;
}

.netweek-hero {
    .hero-content {
        min-height: auto;
    }

    .wsj-logo {
        width: 250px;
    }

    @media (min-width: 64rem) {
        .wsj-logo {
            width: 350px;
        }
    }
}

@media (min-width: 64rem) {
    .grid-gap-xxl-custom {
        --grid-gap: var(--space-xxl);
    }
}

.max-width-logo {
    max-height: 3rem;
}

.max-width-logo-sm {
    max-height: 2rem;
}

@media (max-width: 768px) {
    .grid-small-items {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 0;
    }
}


