@import url("https://use.typekit.net/geg3sxw.css");
/* ReworkText-Thin */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Thin.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

/* ReworkText-ThinItalic */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-ThinItalic.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

/* ReworkText-Extralight */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Extralight.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Extralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

/* ReworkText-ExtralightItalic */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-ExtralightItalic.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-ExtralightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

/* ReworkText-Light */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Light.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* ReworkText-LightItalic */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-LightItalic.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

/* ReworkText-Regular */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Regular.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* ReworkText-RegularItalic */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-RegularItalic.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

/* ReworkText-Semibold */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Semibold.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Semibold.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

/* ReworkText-SemiboldItalic */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-SemiboldItalic.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-SemiboldItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

/* ReworkText-Semibold */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Semibold.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Semibold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* ReworkText-SemiboldItalic */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-SemiboldItalic.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-SemiboldItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

/* ReworkText-Bold */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Bold.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* ReworkText-BoldItalic */
@font-face {
  font-family: 'ReworkText';
  src: url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-BoldItalic.woff2') format('woff2'),
       url('https://cdn.theyachtweek.com/assets/fonts/rework/ReworkText-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

:root {
  // font family
  //--font-secondary: ReworkText, sans-serif;
  --font-secondary: Anton, sans-serif;
  --font-tertiary: 'Antonio', sans-serif;
}
body {
  font-family: Inter, serif;
  font-weight: 200;
  @apply dui-text-primary-950;
}

h1,h2,h3,h4,h5,h6 {
  @apply dui-text-primary-950;
}

h1 {
    font-family: var(--font-secondary);
    text-transform: uppercase; /* Uppercase the text in the headers */
}

h2,
h3 {
    font-family: var(--font-tertiary);
    font-weight: 600 !important;
    font-style: normal;
}

/* TEMP */
.old-style-itinerary {
  .map-card {
    .d8-c-card {
      border-radius: 0 !important;
      box-shadow: none !important;
      border: 2px solid;
      @apply dui-border-primary-950;
    }
  }
}

.slick-dots {
  display: flex !important;
  @apply dui-pt-4;
  @apply dui-gap-2;

  li {
    font-size: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    @apply dui-bg-primary-100;

    button {
      width: 12px;
      height: 12px;
      @apply dui-cursor-pointer;
    }

    &.slick-active {
      @apply dui-bg-primary-950;
    }
  }
}

.slick-track {
  margin-left: 0;
}

.klaviyo-form {
  form {
    float: none !important;
    @apply dui-mx-auto;
  }
}