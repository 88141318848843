.destination-peche {
    --color-primary: #ED7867;
    --color-secondary: #F6C6B5;
    --color-bg: #F6C6B5;

    .d8-c-card-slider {
        .card-slider-controls {
            &.right {
                background: none;
            }
            &.left {
                background: none;
            }
        }
    }
}
