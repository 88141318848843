.destination-yachtnik {
    --color-primary: #00aeef;
    --color-secondary: #004a80;
    .pill--purple-outline {
        color: var(--color-secondary);
        border: 1px solid var(--color-secondary);
    }
    .d8-u-primary-bg {
        background-color: var(--color-secondary);
    }
}
