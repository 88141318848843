.tyw-c-destination-guide{
    background-color: var(--color-white);
    .destination-guide__wrapper{
        min-height: -webkit-fill-available;
        height: 100vh;
        scroll-snap-type: y mandatory;
        @include breakpoint(sm) {
            min-height: 100vh;
        }
        .destination-item{
            scroll-snap-align: start;
            width: 100vw;
            min-height: -webkit-fill-available;
            height: 100vh;
            max-width: 100%;
            @include breakpoint(sm) {
                min-height: 100vh;
            }
            .overlay{
                background: linear-gradient(180deg, rgba(16, 16, 16, 0.0001) 0%, #101010 100%);
            }
            .pills {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        .scroll-cta {
            .scroll-cta-button {
                width: 82px;
                height: 82px;
                border: 1px solid var(--color-white);
                border-radius: var(--radius-full);
            }
        }

    }
    .destination-guide-nav {
        bottom: 0;
        z-index: var(--zindex-header);
        .nav__wrapper {
            background: white;
            border-top-left-radius: 26px;
            border-top-right-radius: 26px;
            background: rgba(216, 229, 246, 0.2);
            backdrop-filter: blur(15px);
            position: relative;
            .nav-item {
                @include breakpoint(sm) {
                    &:first-child{
                        margin-left: var(--space-lg);
                    }
                    &:last-child{
                        margin-right: var(--space-lg);
                    }
                }
                max-width: 300px;
                min-width: 300px;
                cursor: pointer;
                .item-img {
                    width: 78px;
                    height: 78px;
                }
                &.active {
                    background: rgba(216, 229, 246, 0.2);
                    border-radius: 26px;
                }
                padding: 0;
            }
            .destination-guide-nav-absolute {
                position: absolute;
                top: 0;
                left: 0;
            }

        }
        .d8-c-card-slider {
            padding: 0;
        }
        .nav-control {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(-50%, -50%);
            &.prev {
                right: unset;
                left: 0;
                transform: translate(50%, -50%);
            }
        }
    }
}

.d8-c-contact-banner {
    background-blend-mode: darken;
    overflow: hidden;
    background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2024/01/contact-sardinia.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--color-primary);
    h2, h3, h4, h5, p {
        color: var(--color-bg);
    }
}

.bottom-over {
    bottom: -2px;
}

.svg-tuck {
    @include breakpoint(sm) {
        margin-top: -62vh;
    }
}

.mobile-svg-tuck {
    margin-top: -50vh;
}

.backdrop {
    backdrop-filter: blur(5px);
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.2);
    transition: all 0.7s ease;
}

.hover-link {
    pointer-events: none;
    opacity: 0;
}

.hover-link-show {
    &:hover .hover-link {
        opacity: 1;
    }
}

.mobile-only {
    @include breakpoint(md) {
        display: none;
    }
}