.tyw-c-how-it-works-packages {
    background-position: center;
    background-size: cover;
    margin-top: 0;
    padding-top: var(--space-xs);
    margin-bottom: 0;
    padding-bottom: var(--space-xs);
    .number {
      position: absolute;
      left: 32px;
      top: 24px;
      font-size: 60px;
      @include day8-small {
        top: -60px;
        font-size: 80px;
        left: 20%;
      }
      font-weight: bold;
      color: #5397b5;
    }
    .svg1 {
      position: absolute;
      top: -40px;
      left: -40px;
      opacity: 0.5;
    }

    .svg2 {
      position: absolute;
      right: 20px;
      @include day8-medium {
        display: block;
        bottom: -120px;
        right: -120px;
        opacity: 0.7;
      }
    }
}

.tyw-c-how-it-works-payment {
    overflow: hidden;
    margin-top: 0;
    padding-top: var(--space-xs);
    margin-bottom: 0;
    padding-bottom: var(--space-xs);

    .number {
      position: absolute;
      left: 32px;
      top: 24px;
      font-size: 60px;
      @include day8-small {
        top: -30px;
        font-size: 80px;
        left: 60%;
      }
      font-weight: bold;
      color: #5fcb71;
    }

    .svg1 {
      position: absolute;
      left: -60px;
      top: -50px;
      transform: rotate(5deg);
      opacity: 0.5;
    }
    .svg2 {
      position: absolute;
      right: -40px;
      bottom: -110px;
    }
}

.tyw-c-how-it-works-crew {
    position: relative;

    @include day8-small {
      .crew-image {
        position: relative;
      }
    }
    .number {
      position: absolute;
      left: 32px;
      top: -48px;
      font-size: 60px;
      @include day8-small {
        left: auto;
        font-size: 80px;
        top: -50px;
        left: 30%;
      }
      font-weight: bold;
      color: #ed662f;
      z-index: 2;
    }
}

.tyw-c-how-it-works-routes {
    background-position: center;
    background-size: cover;
    margin-top: 0;
    padding-top: var(--space-xs);
    margin-bottom: 0;
    padding-bottom: var(--space-xs);


    .number {
      position: absolute;
      left: 32px;
      top: 24px;
      font-size: 60px;
      @include day8-small {
        top: -60px;
        font-size: 80px;
        left: 80%;
      }
      font-weight: bold;
      color: #1f2e83;
    }

    .svg1 {
      position: absolute;
      top: 70%;
      left: 20%;
    }

    .svg2 {
      display: none;
      @include day8-small {
        display: block;
        position: absolute;
        bottom: -120px;
        right: 20px;
      }
    }
}

.d8-c-crew-grid {
      position: relative;
      @include grid;
      @include gridAdvanced((3, 13, 3, 8), (2, 12, 2, 7));
      .svg {
        position: absolute;
        right: 0;
        bottom: -20px;
      }
}

.d8-c-team-grid {
      position: relative;
      @include grid;
      @include gridAdvanced((1, 10, 3, 8), (2, 12, 2, 7));
      .svg {
        position: absolute;
        left: 0;
        bottom: -20px;
      }
}
