@import "../../node_modules/day8-react/build/styles/design-system/custom-style/_grid-mixins.scss";
.destination-tahiti {
    --color-tahiti-green: hsl(178, 84%, 25%);
    --color-bg: hsl(38, 46%, 92%);
    --color-contrast-low: hsl(38, 34%, 88%);
    --color-extra: #1C511E;
    .d8-c-hero{
        .btn.btn--primary {
            background-color: var(--color-primary);
            color: var(--color-white);
        }
    }
    .tyw-c-intro {
        .intro-top{
            svg {
                path,
                g {
                    stroke: var(--color-tahiti-green);
                }
            }
            p{
                color: var(--color-tahiti-green);
            }
            .content{
                p{
                    color: var(--color-black);
                }
            }
        }
        .top-bottom{
            .d8-u-color-contrast-higher{
                color: var(--color-tahiti-green);
            }
        }
    }
    .tyw-c-key-facts{
        background-image: url(https://cdn.theyachtweek.com/assets/destinations/tahiti/tahiti-sections-pattern.svg);
        padding: calc(4 * var(--component-padding)) 0;
    }
    .tyw-c-destination-reviews {
        background-image: url(https://cdn.theyachtweek.com/assets/destinations/tahiti/tahiti-sections-pattern.svg);
        background-color: #E1EBDA;
    }
    .pill--primary-outline {
      background-color: transparent;
      color: var(--color-tahiti-green);
      border: 1px solid var(--color-tahiti-green);
      svg {
          path,
          g {
              stroke: var(--color-tahiti-green);
          }
      }
    }
    .pill--purple-outline {
      background-color: transparent;
      color: var(--color-extra);
      border: 1px solid var(--color-extra);
      svg {
          path,
          g {
              stroke: var(--color-extra);
          }
      }
    }
}

.tyw-c-tahiti-decoration {
    display: none;
    @media only screen and (min-width: 1100px){
        display: block;
    }
    .decoration__wrapper{
        position: absolute;
        top: 0;
        right: 0;
        .tribals-svg {
            position: absolute;
            top: 0;
            right: 0;
            max-width: 462px;
        }
        .grid__wrapper{
            @include grid;
            @include gridAdvanced((1, 8, 2, 8), (7, -1, 3, 7), (5, -1, 3, 7));
            .destination-1 {
                position: relative;
                max-width: 325px;
                height: auto;
            }
            .destination-2 {
                position: relative;
                max-width: 192px;
                height: auto;
            }
            .tahiti-svg {
                position: relative;
                max-width: 172px;
            }

        }
        @media only screen and (min-width: 1500px) {
            .tribals-svg {
                max-width: 660px;
            }
            .grid__wrapper{
                @include grid;
                @include gridAdvanced((1, 8, 2, 8), (7, -1, 3, 7), (5, -1, 4, 7));
                .destination-1 {
                    max-width: 465px;
                }
                .destination-2 {
                    max-width: 275px;
                }
                .tahiti-svg {
                    max-width: 246px;
                }
            }
        }
    }

}