.tyw-home-leaf-deco {
  background: url(https://cdn.theyachtweek.com/assets/destinations/caribbean/deco4.svg) top left no-repeat;
  margin-top: 0;
  background-size: contain;
}

.tyw-home-leaf-deco-2 {
  background: url(https://cdn.theyachtweek.com/assets/destinations/caribbean/deco7.svg) top right no-repeat;
  background-size: contain;
}

.d8-c-home-video-card {
  min-width: 320px;

  @media only screen and (min-width: 800px) {
    min-width: var(--max-width-sm);
    min-height: 480px;

    .card--image .image--horizontal {
      min-height: 480px;
    }
  }
}

.padding-up-carousel-hero {
  .container {
    .justify-between {
      padding-bottom: 80px;
    }
  }

  @include breakpoint(md) {
    .container {
      .justify-between {
        padding-bottom: 80px;
      }
    }
  }
}

.d8-c-sticky-banner-contain {
  @media (max-height: 767px) {
    position: relative;
  }
}

.d8-c-sticky-banner-dark {
  background-color: black !important;

  &>div {
    padding-top: var(--space-sm) !important;
    padding-bottom: var(--space-sm) !important;
  }
}

.bottom-tear-contain {
  position: absolute;
  height: 14px;
  bottom: 0;
  left: 0;
  width: 100%;
}

.bottom-tear {
  position: absolute;
  left: 0;
  bottom: -3px;
  min-width: 100%;
  width: 1800px;
  max-width: unset;
}

.bg-icon {
  position: absolute;
  top: -8vh;
  width: 55vw;
  max-height: 80vh;
  height: auto;
  z-index: 0;
  animation: float 5s ease-in-out infinite;
}

.bg-icon-left {
  left: -10%;
}

.bg-icon-right {
  right: -10%;
}

.bg-icon-alt {
  width: 40vw;

  @include breakpoint(md) {
    top: 0;
    width: 25vw;
    opacity: 0.3;
  }
}

.bg-icon-top-gap {
  @include breakpoint(md) {
    top: 7vh;
  }
}

@keyframes float {
  0% {
    transform: translatey(2vh);
  }

  50% {
    transform: translatey(8vh);
  }

  100% {
    transform: translatey(2vh);
  }
}

@keyframes floataround {
  0% {
    transform: translatex(0vh) translatey(0vh);
  }

  25% {
    transform: translatex(2.5vh) translatey(2.5vh);
  }

  50% {
    transform: translatex(0vh) translatey(5vh);
  }

  75% {
    transform: translatex(-2.5vh) translatey(-2.5vh);
  }

  100% {
    transform: translatex(0vh) translatey(0vh);
  }
}

//footer updates 
.d8-c-footer {
  color: #fff;
  .d8-u-color-contrast-high {
    color: #fff;
  }

  svg {

    g,
    path,
    polygon,
    rect {
      fill: #fff !important;
    }

    &.d8-svg-facebook-brand-2 {
      &>g>path:nth-child(2) {
        fill: var(--color-brand-primary) !important;
      }
    }
  }
}