.bbyl {
    .ftdr {
        .area {
            padding-bottom: 0;
        }
    }
    .code-box {
        position: relative;
        max-width: 100%;
        margin-top: -70px;
        @include day8-small-down {
            width: 100%;
        }
        @include day8-small {
            max-width: 536px;
            margin-left: auto;
        }
        .wrapper {
            @include day8-small-down {
                width: 100%;
            }
        }
        .code-box__wrapper {
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            background: $primary-bg;
            padding: 20px;
            color: #fff;
            text-align: center;
            .code__wrapper {
                font-size: 24px;
                font-weight: bold;
                span {
                    padding: 0 10px;
                }
            }
            .buttons__wrapper {
                .btn {
                    margin: 0 10px;
                    @include day8-small {
                        margin-left: 20px;
                    }
                }
            }
            @include day8-small-down {
                width: 100%;
            }
            @include day8-small {
                // transform: translateX(40px);
                text-align: right;
            }
        }
    }
    .what-you-get-row {
        .what-you-get-row__item {
            text-align: center;
            .item-top {
                .icon__wrapper {
                    width: 50px;
                    height: 50px;
                    border-radius: 14px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &.turquoise {
                        background: #19a9a3;
                    }
                    &.blue {
                        background: #182c4b;
                    }
                    &.green {
                        background: #1dd856;
                    }
                }
            }
        }
    }
    .boxes-row {
        .box {
            // padding-bottom: 50%;
            min-height: 400px;
            background-size: cover;
            background-position: center;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            &.tyw {
                color: #fff;
            }
            .box__wrapper {
                padding: 20px;
                position: relative;
                width: 100%;
                @include day8-small {
                    width: 75%;
                }
            }
        }
    }
}
