
.d8-c-media-row {
  @include day8-small-down {
    .media-wrapper {
      top: -60px;
    }
  }
}

.d8-c-artist-banner {
  min-height: 300px;

  @include day8-large {
    min-height: 420px;
  }
}
