.sign-up-2022 {
  --color-bg: #fff;
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 100%;
  .d8-c-hero {
    @include breakpoint(sm, "(orientation: portrait)") {
      height: 50vh !important;
    }
    @include breakpoint(sm, "(orientation: landscape)") {
      height: 75vh;
    }
    @include breakpoint(lg, "(orientation: portrait)") {
      height: 32vh !important;
    }
    // @include breakpoint(lg, "(orientation: landscape)") {
    //   height: 75vh;
    // }
    @include breakpoint(md) {
      height: 100vh;
    }
    @media (min-width: 1800px) {
      height: 75vh;
    }
  }
  .tyw-logo-svg {
    width: 180px;
    height: 180px;
  }
  .hero_half_circle-svg {
    bottom: -1px;
  }
  .wiggle-svg {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .half_circle-svg {
    top: 0;
    right: 0;
  }
  .tabs_decoration_1-svg {
    top: 0;
    left: 0;
  }
  .tabs_wiggle-svg {
    top: 80%;
    right: 0;
  }
  .tabs_decoration_2-svg {
    top: 0;
    right: 0;
  }
  .tabs_circle-svg {
    top: -40%;
    left: 0;
  }
  .flex-highlight-grid-wrapper {
    .d8-c-card-slider {
      .slider--row {
        .card-slider-controls {
          background: transparent;
        }
      }
    }
  }
  .signup-footer-wrapper {
    background-image: url(https://cdn.theyachtweek.com/assets/icons/footer_rect_1.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}