.destination-sardinia-40 {
    --color-emerald: #009874;
    --color-bg: var(--color-emerald);

    #__next {
        --color-bay: #bfeada;
        --color-bg: var(--color-emerald);
        --color-primary: var(--color-bay);
        --color-primary-darker: var(--color-emerald);
        --color-primary-dark: var(--color-emerald);
        --color-contrast-low: var(--color-emerald);
        --color-contrast-lower: var(--color-emerald);
        --color-contrast-high: var(--color-white);

        overflow: hidden;

        .tabs--content p {
            color: var(--color-white);
        }

        .d8-c-navbar {
            .d8-u-color-contrast-high {
                color: var(--color-black);
            }
        }

        .d8-c-contact-banner {
            p {
                color: var(--color-white);
            }

            .btn--ghost {
                border-color: var(--color-white);
                color: var(--color-white);
            }
        }

        .btn--secondary {
            background-color: var(--color-primary-dark);
            border-color: var(--color-primary-dark);
        }

        .btn--ghost {
            border-color: var(--color-primary-dark);
            color: var(--color-primary-dark);
            &:hover {
                color: var(--color-white);
            }
        }

        .btn--white {
            --color-contrast-high: var(--color-primary);
            --color-white: var(--color-primary-darker);
        }

        .btn--circle-icon-ghost:focus,
        .btn--circle-icon-ghost:hover {
            background-color: var(--color-primary-darker);
        }

        .d8-c-card-slider {
            .card-slider-controls {
                &.right {
                    background: none;
                }

                &.left {
                    background: none;
                }
            }
        }

        h2 {
            color: var(--color-white);
        }

        .d8-u-text-bold.text-md.margin-top-sm.d8-u-color-black,
        p.margin-bottom-lg {
            color: var(--color-white);
        }

        .intro-wrapper {
            .text-component {
                color: var(--color-white);

                h2,
                h3,
                h4,
                h5,
                h6 {
                    color: var(--color-white);
                }
            }
        }

        button.btn--primary {
            color: var(--color-primary-darker);

            &:hover {
                color: var(--color-white);
                border: 1px solid var(--color-white);
            }
        }
    }
}