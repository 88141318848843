.ads-landing-page {
    @media screen and (max-width: 374px){
        .text-component{
            h1.text-xxxl {
                font-size: var(--text-xxl);
            }
            .subtitle {
                margin: auto;
            }
        }
        
    }
}