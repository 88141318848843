.destination-turkey {
  --color-bg: #C84D54;
  --color-primary: #CFA187;
  --color-contrast-low: #C84D54;
  --color-contrast-high: hsl(0, 0%, 15%);
  --color-contrast-medium: hsl(0, 0%, 15%);
  --color-contrast-high: hsl(240, 24%, 96%);
  --color-black: hsl(0, 0%, 15%);
  --color-white: hsl(240, 24%, 96%);


  h1,
  h2,
  h3.d8-u-ls-sm,
  h4,
  h5,
  .text-component,
  .d8-u-color-black,
  .btn--underline,
  .d8-u-text-semi-bold,
  .text-sm.padding-bottom-lg,
  .text-component.margin-bottom-xl.text-center,
  .logo,
  .d8-u-text-bold.text-md {
    color: var(--color-white);
  }

  h3.card--image__heading.text-md {
    color: var(--color-white) !important;
  }

  .d8-u-color-primary {
    color: var(--color-primary) !important;
  }

  .text-component.text-center,
  .card--basic__heading,
  h3.d8-u-ls-sm.text-xl {
    color: var(--color-black)
  }

  .btn--white {
    color: var(--color-white);
    background-color: var(--color-black);
  }

  .text-component {
    h3.text-md {
      color: var(--color-black);
    }

    h3 {
      color: var(--color-white);
    }
  }

  .logo {
    svg {

      path,
      g {
        fill: var(--color-white);
        stroke: var(--color-white);
      }
    }
  }

  .icon.margin-left-sm {
    g {
      path {
        fill: var(--color-white);
      }
    }
  }

  .destinations-included-section {

    .d8-c-card-slider {
      .d8-c-card {
        background: var(--color-primary) !important;
        color: var(--color-black) !important;
      }
    }

    .icon.icon--lg,
    .d8-svg-cameras {
      path {
        stroke: var(--color-white);
      }
    }

  }

  .d8-c-navbar {
    background: var(--color-contrast-low);
  }

  .d8-c-card-slider {
    .card-slider-controls {
      &.right {
        background: none;
      }

      &.left {
        background: none;
      }
    }
  }

  .footer--content {

    .d8-svg-facebook-brand-2 {
      path:first-child {
        fill: transparent;
      }
    }

    .icon.icon--md:not(.d8-svg-facebook-brand-2) {
      path {
        stroke: var(--color-white);
        fill: none;
      }
    }

    .btn--ghost {
      background-color: var(--color-white);
      color: var(--color-black);
    }

    .btn--ghost:hover {
      color: var(--color-white);
    }

  }

  .partners-links {

    .d8-svg-aegean-logo,
    .d8-svg-qd-logo,
    .d8-svg-tsw-logo {
      path {
        fill: var(--color-white);
      }
    }

    .d8-svg-ynf-logo {

      g,
      path {
        fill: var(--color-black);
        stroke: var(--color-white);
      }
    }
  }

  .btn.btn--bare {
    color: var(--color-white);
  }

  .btn.btn--bare:hover,
  .btn.btn--underline:hover {
    background: none;
  }

  .btn:hover {
    background: var(--color-black);
  }

  .d8-c-collapse--content {
    h4 {
      color: var(--color-black);
    }
  }

  #tyw-c-weekly-tabs-wrapper {
    h2 {
      color: var(--color-black);
    }
  }
}