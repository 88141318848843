.destination-wanderland {
  .intro-wrapper {
    @media screen and (max-width: 600px) {
      margin-top: 5%;
    }
  }
  .d8-c-wanderland-decoration {
    height: 100vw;
    width: 100vw;
    position: absolute;
    @media screen and (max-width: 600px) {
      height: 100%;
      width: 100%;
    }
    .beach-image {
      position: absolute;
      z-index: -1;
      height: 100%;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2022/03/BODY-SCENE.png');
      @media screen and (max-width: 600px) {
        height: 200%;
        width: 100%;
        position: absolute;
        background-size: cover;
      }

      .womanYellowDingy{
        left: 40%;
        top: 28%;
        @include breakpoint(sm) {
          top: 19%;
        }
      }
      .womanPurpleDingy{
        width: 8%;
        height: 8%;
        right: 2%;
        top: 27%;
      }
      .womanSwimming{
        top: 9%;
        left: 3%;
      }
      .manSwimmingBack{
        width: 5%;
        height: 5%;
        top: 3%;
        left: 50%;
        @include breakpoint(sm) {
          top: 17%;
          left: 10%;
        }
      }
      .manSwimmingArms{
        width: 7%;
        height: 7%;
        right: 10%;
        top: 13%;
        @include breakpoint(sm) {
          right: 5%;
          top: 8%;
        }
      }
    }
  }
}

