@import url("https://use.typekit.net/cah4xqg.css");

.sign-up-2023 {
  background-color: white;
  --color-secondary: #00D6C6;
  --color-error: #FF4858;

  h1,
  h2 {
    // font-family: bebas-neue, sans-serif;
    // font-weight: 100;
    // line-height: 1;
  }

  .d8-c-hero {
    height: 100%;
  }

  .hero-content {
    min-height: calc(100vh - 30px);
    text-align: left;

    & > div {

      @include breakpoint(md) {
        height: 81vh;
      }
      display: flex;
      align-items: center;
    }

    @include breakpoint(sm, "(orientation: portrait)") {
      .title-section {
        text-align: center;
        font-size: 25px;
      }
    }

    @include breakpoint(md) {
      min-height: calc(100vh - 50px);
      text-align: left;
    }

    h1 {

      @include breakpoint(md) {
        font-size: 70px;
      }

      // @include breakpoint(xl) {
      //   font-size: 80px;
      // }
    }
  }
  .agents-page {
    .hero-content {
      @include breakpoint(sm, "(orientation: portrait)") {
        .title-section {
          text-align: inherit;
          font-size: inherit;
        }
      }
    }
  }
  .fish-swish-svg {
    width: 100%;
    height: auto;
    transform: translateY(50%);
    z-index: 1;

    svg {
      width: 100%;
      height: auto;
    }
  }

  .custom-large-h2 {
    svg {
      width: 54px;
      height: auto;
    }
    @include breakpoint(md) {
      font-size: 80px;

      svg {
        width: 100px;
        height: auto;
      }
    }
  }

  .what-is-tyw {
    p {
      line-height: 2.5;
    }

    @include breakpoint(sm, "(orientation: portrait)") {
      padding-top: var(--space-xxl);
    }

    @media (min-width: 1800px) {
      padding-top: 70px;
      padding-bottom: 140px;
    }

    .welcome-to-the-best-week-svg__wrapper {

      .welcome-to-the-best-week-svg {
        right: 0;
        bottom: 0;
        overflow: hidden;

        svg {
          position: relative;
          right: -15%;
          width: 160px;
          height: auto;

          @include breakpoint(md) {
            width: 415px;
          }
        }
      }
    }
  }

  .where-to__wrapper {
    background-image: url(https://cdn.theyachtweek.com/assets/sign-up-2023/white-swish.svg);
    background-color: rgba(19, 51, 100, 0.9);
    background-position: center -1px;

    @include breakpoint(md) {
      background-position: bottom;
    }
  }

  .myth-busting {
    overflow: hidden;

    .bullet-points {
      .odd {
        transform: translateX(-10%);

        @include breakpoint(sm, "(orientation: portrait)") {
          transform: translateX(-5%);
        }

        @include breakpoint(md) {
          transform: none;
        }
      }

      .even {
        transform: translateX(10%);

        @include breakpoint(sm, "(orientation: portrait)") {
          transform: translateX(5%);
        }

        @include breakpoint(md) {
          transform: none;
        }
      }
    }
  }

  .yachts-section {
    @media (min-width: 1800px) {
      padding-top: 100px;
    }
  }

  .destination-tabs-2023 {
    .extra-line-height {
      line-height: 2.5;
    }

    .vertical-info {

      .vertical-info__left,
      .vertical-info__right {
        width: 40%;
      }
    }

    .d8-c-tabs {
      .tabs--options__wrapper {
        display: flex;

        @include breakpoint(md) {
          max-width: 40%;
        }

        .btn {
          flex: 1;
          margin-right: var(--space-sm);
          background-color: var(--color-contrast-low);
          border-color: var(--color-contrast-low);
          color: var(--color-black);

          &.active {
            background-color: var(--color-secondary);
            border-color: var(--color-secondary);
            color: var(--color-white)
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .tyw-logo-svg {
    font-size: 285px;
    height:auto;
    margin-left: -14px;
  }


  .scrolling-banner {
    height: 30px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    position: sticky;
    top: 0;
    background-color: rgba(#ff4858, 1);
    z-index: 5;

    &:hover div {
      animation-play-state: paused;
    }

    div {
      display: flex;
      position: relative;
      animation: marquee 30s linear infinite;
      justify-content: space-around;
    }

    p {
      display: block;
      height: 30px;
      min-width: 400px;
      line-height: 30px;
      margin: 0 1vw;
      text-transform: uppercase;
    }

    &.netweek {
      div {
        animation: marquee 60s linear infinite;
      }

      p {
        min-width: 535px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    @include breakpoint(md) {
      height: 50px;

      p {
        height: 50px;
        line-height: 50px;
      }
    }
  }

  .rotating {
    -webkit-animation: rotating 10s linear infinite;
    -moz-animation: rotating 10s linear infinite;
    -ms-animation: rotating 10s linear infinite;
    -o-animation: rotating 10s linear infinite;
    animation: rotating 10s linear infinite;
  }

  .destination-card-slider {
    .d8-c-card-slider {
      .card-slider-controls {
        background: none;
      }
    }
  }

  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-100%);
    }
  }


  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      transform-origin: center;
    }

    to {
      -ms-transform: rotate(-360deg);
      -moz-transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
      -o-transform: rotate(-360deg);
      transform: rotate(-360deg);
      transform-origin: center;
    }
  }

  .vertical-align-middle {
    vertical-align: middle;
  }
}