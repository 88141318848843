@import "../../node_modules/day8-react/build/styles/design-system/custom-style/_grid-mixins.scss";
@import url("https://use.typekit.net/ykj2xft.css");

.destination-cirquedusail {
    // --color-contrast-low: hsl(38, 34%, 88%);
    --color-black: #000000;
    --color-white: #ffffff;
    --color-cirquedusail-green: #14ffa0;
    --color-cirquedusail-pink: #ff96fa;
    --color-cirquedusail-cream: #fff0be;
    --color-bg: var(--color-cirquedusail-green);
    --color-primary: var(--color-black);
    --color-secondary: var(--color-cirquedusail-pink);
    --font-title: termina, sans-serif;

    .d8-c-navbar {
        background: var(--color-cirquedusail-pink);

        .d8-c-avatar {
            background: var(--color-cirquedusail-green);

            .d8-u-color-white {
                color: var(--color-black) !important;
            }
        }
    }

    .d8-c-cirquedusai-decoration {
        padding: 1em;
        background-color: var(--color-cirquedusail-pink);

        div {
            height: 6em;
            background-size: contain;
            background-image: url('https://assets.theyachtweek.com/wp-content/uploads/2021/04/ornament.png');
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: var(--font-title);
    }

    .map-card .d8-u-color-primary {
        font-family: var(--font-title);
    }

    .d8-c-sticky-navbar {
        background: var(--color-cirquedusail-cream);

        .d8-u-color-contrast-high {
            color: var(--color-black);
        }

        .text-md {
            font-family: var(--font-title);
        }
    }

    p a {
        text-decoration: underline;
    }

    .btn {
        font-family: var(--font-title);

        &.btn--white {
            color: var(--color-black);
            background-color: var(--color-cirquedusail-pink);

            svg {

                g,
                path {
                    fill: var(--color-black);
                    stroke: var(--color-black);
                }
            }
        }

        &.btn--ghost {
            color: var(--color-black);
            border-color: var(--color-black);
        }
    }

    .d8-c-hero {
        .btn.btn--primary {
            background-color: var(--color-primary);
            color: var(--color-white);
        }
    }

    .tyw-c-intro {
        .intro-top {
            svg {

                path,
                g {
                    stroke: var(--color-black);
                }
            }

            p {
                color: var(--color-black);
            }

            .content {
                p {
                    color: var(--color-black);
                }
            }
        }

        .top-bottom {
            .d8-u-color-contrast-higher {
                color: var(--color-cirquedusail-pink);
            }
        }

        a {
            color: var(--color-cirquedusail-pink);
        }
    }

    .d8-c-tabs {
        .btn--underline {
            font-size: var(--text-sm);
            color: var(--color-black);

            &.active {
                color: var(--color-black);
            }
        }
    }

    .d8-c-carousel {
        .btn--circle-icon-ghost {
            background-color: var(--color-black);
            border-color: var(--color-black);
            color: var(--color-white);

            svg {

                path,
                g {
                    fill: var(--color-white);
                    stroke: var(--color-white);
                }
            }

        }

    }

    .d8-c-destination-map {
        .map-card {
            .d8-svg-location-bold {

                g,
                path {
                    fill: var(--color-cirquedusail-pink);
                    stroke: var(--color-cirquedusail-pink);
                }
            }

            .d8-u-color-primary {
                color: var(--color-cirquedusail-pink);
            }

            .btn--circle-icon-primary {
                color: var(--color-white);
            }
        }
    }

    #tyw-c-weekly-tabs-wrapper {
        .d8-u-contrast-lower-bg {
            background-color: transparent;
        }

        .btn {
            font-size: 10px;
        }

        .d8-u-font-size-base {
            font-family: var(--font-primary);
        }
    }

    .d8-c-footer {
        background-color: var(--color-cirquedusail-pink);

        .d8-u-color-contrast-high {
            color: var(--color-black);
        }
    }

    .pill--primary-outline {
        background-color: transparent;
        color: var(--color-black);
        border: 1px solid var(--color-black);

        svg {

            path,
            g {
                stroke: var(--color-black);
            }
        }
    }

    .pill--purple-outline {
        background-color: transparent;
        color: var(--color-black);
        border: 1px solid var(--color-black);

        svg {

            path,
            g {
                stroke: var(--color-black);
            }
        }
    }

    .d8-u-contrast-low-bg {
        background-color: var(--color-cirquedusail-cream);
    }

    .d8-c-collapse.no-padding header {
        background: var(--color-cirquedusail-pink);
    }

    .d8-c-hero {
        @media only screen and (max-width: 530px) {
            .container .flex {
                flex-direction: column;
            }
        }
    }

    .d8-c-cirquedusail-rope-1 {
        z-index: 1;
        right: -2%;
        top: 0;
        width: 25%;
    }

    .d8-c-cirquedusail-rope-2 {
        z-index: 1;
        left: -5%;
        bottom: 0;
        width: 20%;
    }

    .mobile--menu {
        background: var(--color-cirquedusail-cream) !important;
    }

    .d8-c-card-slider .card-slider-controls.right {
        background: linear-gradient(-90deg, #14ffa0, #14ffa000);
    }

    .d8-c-card-slider .card-slider-controls.left {
        background: linear-gradient(-90deg, #14ffa000, #14ffa0);
    }
}