.light-green-bg,
.rating-2,
.standard-bg,
.rating-Standard {
    background: $light-green;
}

.rating-3,
.premium-bg,
.rating-Premium {
    background: $gray;
}
.rating-badge {
    &.gray-bg {
        background: $gray;
    }
}

.yellow-bg,
.rating-4,
.premium-plus-bg,
.rating-Premium.plus-badge {
    background: $yellow;
}

.lime-bg,
.rating-basic {
    background: $lime;
}

.purple-bg,
.rating-premium {
    background: $purple;
}
.tab-badges {
    .rating-badge {
        opacity: 0.3;
    }

    .active-tab {
        border: none;
        .rating-badge {
            opacity: 1;
        }
    }
}
.rating-badge {
    display: inline-block;
    padding: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 2px;
    margin-bottom: 8px;
    min-width: 70px;
    cursor: pointer;
}

.contact-dropdown {
    ul {
        margin: 0px;
        li {
            margin-bottom: 10px;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}

.campaign-1 {
    display: none;
    @include day8-small {
        z-index: -1;
        display: block;
        font-size: 130px;
        font-weight: bold;
        font-style: normal;
        bottom: -150px;
        left: -20%;
        opacity: 0.1;
        margin-bottom: 0;
    }
}

.campaign-2 {
    display: none;
    @include day8-small {
        z-index: -1;
        display: block;
        font-size: 130px;
        font-weight: bold;
        font-style: normal;
        bottom: -100px;
        right: -2%;
        opacity: 0.1;
        margin-bottom: 0;
    }
}

.easy-jet-form {
    background: #eee;
    padding: 25px;
    @include day8-small {
        padding: 60px;
    }
}

.best-job-form {
    background: #eee;
    padding: 25px;
    @include day8-small {
        padding: 40px;
    }
}

.d8-c-multi-step {
    .form-label {
        text-transform: none;
    }
}